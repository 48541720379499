import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import {userService} from "../../services/user.service";

export const getAllUsers = createAsyncThunk(
    'user/getAllUsers',
    async (_, {rejectWithValue, getState}) => {
        try {
            const state = getState()
            if (!state?.user?.users?.length) {

                return (await userService.getAll(true))
                    .sort((a, b) => b.scoreMath + b.scorePhysics - a.scoreMath - a.scorePhysics)
                    .map((user, id) => {
                        user.place = id + 1;
                        return user
                    });
            }
            return state?.user.users
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const getUsersForALlTime = createAsyncThunk(
    'user/getUsersForALlTime',
    async (_, {rejectWithValue, getState}) => {
        try {
            const state = getState()
            if (!state?.user?.allTimeUsers?.length) {
                return (await userService.getAll(false))
                    .sort((a, b) => b.scoreMath + b.scorePhysics - a.scoreMath - a.scorePhysics)
                    .map((user, id) => {
                        user.place = id + 1;
                        return user
                    });
            }
            return state?.user.allTimeUsers
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const getUserPhoto = createAsyncThunk(
    'user/getUserPhoto',
    async ({id}, {rejectWithValue, getState}) => {
        try {
            const response = await userService.getPhoto(id)
            return {data: response}
        } catch (e) {
            return rejectWithValue({error: true});
        }
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        users: [],
        allTimeUsers: [],
        usersPhoto: [],
        sortedUsers: [],
        sortedUsersForTop3: [],
        selectedUser: undefined,
        selectedSubject: 'all',
        selectedPeriod: 'current',
        sortedString: ''
    },
    reducers: {
        selectUser: (state, action) => {
            const currentUser = {...action.payload.user};
            if (state?.selectedUser?.id !== currentUser.id) {
                state.selectedUser = currentUser
            }
        },
        setSelectedSubject: (state, action) => {
            state.selectedSubject = action.payload;
        },
        setSelectedPeriod: (state, action) => {
            state.selectedPeriod = action.payload;
        },
        setSortedString: (state, action) => {
            state.sortedString = action.payload;
        },
        saveUsersDefaultPhoto: (state, action) => {
            if (state.usersPhoto.some(x => x.id === action.payload.id)) {
                state.usersPhoto = state.usersPhoto.filter(x => x.id !== action.payload.id)
            }
            state.usersPhoto.push(action.payload)
        },
        deleteUsersDefaultPhoto: (state, action) => {
            state.usersPhoto = state.usersPhoto.filter(x => x.id !== action.payload)
        },
        setSorting: (state, action) => {
            const subject = state.selectedSubject
            const period = state.selectedPeriod
            const sortedString = state.sortedString
            let sorted = []
            let users = []
            if (period.toLowerCase() === 'current') {
                users = state.users
            } else {
                users = state.allTimeUsers
            }

            if (subject === 'math') {

                sorted = users
                    .sort((a, b) => b?.scoreMath - a?.scoreMath)
                    .map((user, id) => {
                        user.place = id + 1;
                        return user
                    });

            } else if (subject === 'physics') {

                sorted = users
                    .sort((a, b) => b?.scorePhysics - a?.scorePhysics)
                    .map((user, id) => {
                        user.place = id + 1;
                        return user
                    });

            } else {
                sorted = users
                    .sort((a, b) => b.scoreMath + b.scorePhysics - a.scoreMath - a.scorePhysics)
                    .map((user, id) => {
                        user.place = id + 1;
                        return user
                    });
            }

            state.selectedUser = sorted.find(x => x.id === state.selectedUser?.id)
            state.sortedUsers = state.sortedUsersForTop3 = sorted

            if (sortedString) {
                state.sortedUsers = state.sortedUsers.filter(user =>
                    user.fullName.toLowerCase().includes(sortedString.toLowerCase()))
            } else {
                state.sortedUsers = state.sortedUsers.slice(3)
            }
        }
    },
    extraReducers: builder =>
        builder
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.users = action.payload
                state.sortedUsersForTop3 = action.payload
                state.sortedUsers = action.payload.slice(3)
            })
            .addCase(getUsersForALlTime.fulfilled, (state, action) => {
                state.allTimeUsers = action.payload
            })
            .addCase(getUserPhoto.fulfilled, (state, action) => {
            })
            .addMatcher(isPending(), state => {

            })
            .addMatcher(isFulfilled(), state => {

            })
            .addMatcher(isRejected(), (state, action) => {
            })
})

export const {
    selectUser,
    setSelectedSubject,
    setSelectedPeriod,
    saveUsersDefaultPhoto,
    deleteUsersDefaultPhoto,
    setSorting,
    setSortedString,
} = userSlice.actions;
export default userSlice.reducer;