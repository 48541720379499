import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from '../SubjectAndPeriod.module.css';
import {setSelectedSubject, setSorting} from "../../../../../store/slices/userSlice";

const Subject = () => {

    const dispatch = useDispatch();

    const handleRadioChange = (event) => {
        const newSelectedSubject = event.target.value;
        dispatch(setSelectedSubject(newSelectedSubject));
        dispatch(setSorting());
    };

    const {selectedSubject} = useSelector(state => state.user);

    return (
        <div className={css.body}>
            <div className={css.main}>
                <div className={css.mainText}>Предмет</div>
                <div>
                    <label className={css.container}>
                        Всі предмети
                        <input
                            checked={selectedSubject === 'all'}
                            type={'radio'}
                            name="subject"
                            value="all"
                            onChange={handleRadioChange}
                        />
                        <span className={css.checkmark}></span>
                    </label>
                    <label className={css.container}>
                        Математика
                        <input
                            checked={selectedSubject === 'math'}
                            type={'radio'}
                            name="subject"
                            value="math"
                            onChange={handleRadioChange}
                        />
                        <span className={css.checkmark}></span>
                    </label>
                    <label className={css.container}>
                        Фізика
                        <input
                            checked={selectedSubject === 'physics'}
                            type={'radio'}
                            name="subject"
                            value="physics"
                            onChange={handleRadioChange}
                        />
                        <span className={css.checkmark}></span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export {
    Subject
};