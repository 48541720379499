import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {decode} from 'base64-arraybuffer';

import css from "./PdfDocument.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfDocument = ({pdfBase64, pageNumber = 1, scale, width, height}) => {

    const [pdfData, setPdfData] = useState(null);

    useEffect(() => {
        const arrayBuffer = decode(pdfBase64);
        setPdfData(new Uint8Array(arrayBuffer));
    }, [pdfBase64]);

    return (
        <>
            {pdfData && pdfBase64.startsWith('data:application/pdf;base64') &&
                <Document file={pdfBase64}>
                    <Page
                        className={css.page}
                        pageNumber={pageNumber}
                        scale={scale}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        //preserveAspectRatio="xMidYMid meet"
                        width={width}
                        height={height}
                    />
                </Document>}
        </>
    );
};

export {PdfDocument};