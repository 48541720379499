import React, {useEffect, useState} from 'react';

import css from './Slide.module.css';
import Button from "./components/Button";

const Slide = ({children}) => {

    const [currentIndex, setCurrentIndex] = useState(0)
    const [startAnimation, setStartAnimation] = useState(false);
    const [direction, setIsAnimationLeft] = useState(false);

    const handleButtonClick = (direction) => {
        setStartAnimation(!startAnimation);
        setIsAnimationLeft(direction !== 'up');
    };

    useEffect(() => {
        if (direction) {
            setCurrentIndex(currentIndex === children.length ? currentIndex : currentIndex + 1)
        } else {
            setCurrentIndex(currentIndex === 0 ? currentIndex : currentIndex - 1)
        }
    }, [startAnimation]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [children]);

    if (!Array.isArray(children)) children = [children]

    return (
        <div className={css.top}>
            <Button button={'left'} disabled={currentIndex} onClick={() => handleButtonClick('up')}/>
            <div className={css.main}>
                {Array.isArray(children) && Boolean(children.length) &&
                    children.map((element, i) => {
                        if(i > currentIndex) element = {...element, props:{...element.props, isDisplayedRightPanel:false}}
                        if (i === currentIndex) {
                            return (
                                <div key={i} style={{zIndex: 10 - i}}
                                     className={`${css.slide} ${css.slideShow} ${direction ? css.showing : css.showingUp}`}>
                                    {element}
                                </div>
                            )
                        } else if (i < currentIndex) {
                            return (
                                <div key={i} style={{zIndex: 10 - i}}
                                     className={`${css.slide} ${css.slideHidden} ${direction ? css.hidden : css.hiddenUp}`}>
                                    {element}
                                </div>
                            )
                        } else {
                            return (
                                <div key={i} style={{zIndex: 10 - i}}
                                     className={`${css.slide} ${!direction ? css['slideBack' + (i - currentIndex)] : css['slideIn' + (i - currentIndex + 1)]}`}>
                                    {element}
                                </div>
                            )
                        }
                    })
                }
            </div>
            <Button button={'right'} disabled={children.length - currentIndex > 1} onClick={() => handleButtonClick('down')}/>
        </div>
    );
};

export {Slide};