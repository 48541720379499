import React from 'react';
import {useSelector} from "react-redux";

import css from './LeftPanel.module.css';
import {Period, Search, Subject, UserDescription} from "../../Elements";

const LeftPanel = () => {

    const {selectedUser} = useSelector(state => state.user);
    return (
        <div className={css.main}>
            <Search/>
            <Subject/>
            <Period/>
            {!!selectedUser?.id && <UserDescription/>}
        </div>
    );
};

export {LeftPanel};