import React from "react";
import {useDispatch} from "react-redux";
import styled from "styled-components";

import {openCreateOrEdit} from "../../../../../store/slices/modalSlice";

const Input = styled.input.attrs(props => ({
    type: "text",
    size: props.small ? 5 : undefined
}))`
  height: 34px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:focus {
    outline: none;
    border: 1px solid #d50181;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

const CreateButton = styled.button`
  border-radius: 5px;
  background-color: #D1007D;
  color: #FFF;
  padding: 0 10px;
  height: 34px;
  text-align: center;
  border: none;
`;

const FilterComponent = ({filterText, onFilter, onClear}) => {
    const dispatch = useDispatch();

    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 7);


    return (
        <div className="d-flex justify-content-between w-100">
            <div className="d-flex gap-2">
                <CreateButton type={"button"} onClick={() => dispatch(openCreateOrEdit({
                    isCreateMode: true,
                    assignment: {
                        fileName: 'New_File',
                        answers: [
                            {description: 'текст', answer: 0, maxScore: 25}
                        ],
                        subject:'Math',
                        difficulty: 1,
                        startDate:currentDate.toISOString().slice(0, 19),
                        endDate:futureDate.toISOString().slice(0, 19)
                    }
                }))}>
                    Створити
                </CreateButton>
            </div>
            <div className="d-flex">
                <Input
                    id="search"
                    type="text"
                    placeholder="Пошук"
                    value={filterText}
                    onChange={onFilter}
                />
                <ClearButton onClick={onClear}>X</ClearButton>
            </div>
        </div>
    )
}

export {FilterComponent};
