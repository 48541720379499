import {ForbiddenPage} from "../components/Pages";

const baseTitle = '2Pnk'

export default baseTitle;

export const titles = {
    login: 'Вхід',
    archive: 'Архів',
    emailConfirm: 'Підтвердження пошти',
    forbiddenPage: 'Не знайдено',
    homePage: 'Вітаємо',
    recovery:'Відновлення паролю',
    admin: 'Адмін',
    register: 'Реєстрація',
    math: 'Математика',
    physics: 'Фізика',
    ratings :'Рейтинг',
    confirmPassword: 'Підтвердження паролю'
}