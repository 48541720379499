import React from 'react';

import css from './loginBasePage.module.css';
import {RightPanelImage} from "../../Elements";

const LoginBasePage = ({children}) => {
    return (
        <div className={css.mainDiv}>
            <div className={css.leftPanel}>
                {children}
            </div>
            <div className={css.rightPanel}>
                <RightPanelImage/>
            </div>
        </div>
    );
};

export {LoginBasePage}