import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {adminService} from "../../services/admin.service";

export const getAllAssignments = createAsyncThunk(
    'admin/getAllAssignments',
    async (_, {rejectWithValue, getState}) => {
        try {
            return await adminService.getAllAssignments();
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const getAllUsers = createAsyncThunk(
    'admin/getAllUsers',
    async (_, {rejectWithValue, getState}) => {
        try {
            return await adminService.getAllUsers()
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        allUsers: [],
        searchTerm: '',
        filteredUsers: [],
        filter1: false,
        filter2: false,
        filter3: false,
        viewedUser: undefined,
        assignments: []
    },
    reducers: {
        setAllUsers: (state, action) => {
            state.allUsers = action.payload;
            state.filteredUsers = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload.toLowerCase();
        },
        setFilter1: (state, action) => {
            state.filter1 = action.payload;
        },
        setFilter2: (state, action) => {
            state.filter2 = action.payload;
        },
        setFilter3: (state, action) => {
            state.filter3 = action.payload;
        },
        filterUsers: (state, action) => {
            state.filteredUsers = state.allUsers.filter(user =>
                user.login.toLowerCase().includes(state.searchTerm) ||
                user.email.toLowerCase().includes(state.searchTerm) ||
                user.fullName?.toLowerCase().includes(state.searchTerm) ||
                user.description?.toLowerCase().includes(state.searchTerm)
            );
            if (state.filter1) {
                state.filteredUsers = state.filteredUsers.filter(user =>
                    (user.emailConfirmStatus && (user.blockedStatus === null))
                );
            }
            if (state.filter2) {
                state.filteredUsers = state.filteredUsers.filter(user =>
                    (user.emailConfirmStatus === false)
                );
            }
            if (state.filter3) {
                state.filteredUsers = state.filteredUsers.filter(user =>
                    (!!user.blockedStatus)
                );
            }
        },
        selectUser: (state, action) => {
            // state.viewedUser = action.payload;
            state.viewedUser = state.filteredUsers.find(user =>
                (user.id === action.payload)
            );
        },
    },
    extraReducers: builder =>
        builder
            .addCase(getAllAssignments.fulfilled, (state, action) => {
                state.assignments = action.payload
            })
            .addMatcher(isPending(), state => {

            })
            .addMatcher(isFulfilled(), state => {

            })
            .addMatcher(isRejected(), (state, action) => {
            })
            .addDefaultCase((state, action) => {})
});

export const {
    setAllUsers,
    filterUsers,
    selectUser,
    setSearchTerm,
    setFilter1,
    setFilter2,
    setFilter3,
} = adminSlice.actions;
export default adminSlice.reducer;