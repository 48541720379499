import React, {useEffect, useRef} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";

import css from './Task.module.css';
import img_1 from './Elements/defaultPDF/pdf1.png';
import img_2 from './Elements/defaultPDF/pdf2.png';
import img_3 from './Elements/defaultPDF/pdf3.png';
import img_4 from './Elements/defaultPDF/pdf4.png';
import downloadButton from './Elements/downloadButton.svg'
import eye from './Elements/eye.svg'
import {openModal, openTask} from "../../../../../store/slices/modalSlice";
import {store} from "../../../../../store";
import {InputAnswer} from "../InputAnswer/InputAnswer";
import {assignmentService} from "../../../../../services/assignment.service";
import {getTaskFile, saveAssignmentFile} from "../../../../../store/slices/taskSlice";

const Task = ({assignment, num}) => {

    const initialState = {
        assignmentId: assignment.id,
        answers: assignment.answers.map((answer) => {
            return {answerId: answer.id}
        })
    }

    const {
        register,
        handleSubmit
    } = useForm({
        defaultValues: initialState
    });
    const leftRef = useRef(null);
    const rightRef = useRef(null);

    const pdfDefaultImages = [img_1, img_2, img_3, img_4];

    useEffect(() => {
        if (leftRef?.current?.offsetHeight) {
            rightRef.current.style.height = `${leftRef?.current?.offsetHeight}px`
        }
    }, [leftRef]);

    const {tasksFile} = useSelector(state => state.task);
    const dispatch = useDispatch()

    const submit = (data) => {
        assignmentService.sendAnswer(data)
            .then(value => {
                if (value.status === 'Success') {
                    dispatch(openModal({message: 'Відповідь була успішно надіслана, і буде перевірена протягом 10 хвилин', class: 'success'}));
                }
            })
            .catch(res => {
                if (!!res?.response?.data?.message) {
                    dispatch(openModal({message: res.response.data.message, class: 'error'}));
                }
            })
    }

    const downloadPdf = () => {
        const downloadLink = document.createElement('a')
        const file = tasksFile.find(x => x.assignmentId === assignment.id)
        if (!file) {
            dispatch(getTaskFile({assignmentId: assignment.id}))
                .then(({payload: data}) => {
                    if (data?.file?.startsWith('data:application/pdf;base64')) {
                        dispatch(saveAssignmentFile(data))
                        downloadLink.href = data?.file;
                        downloadLink.download = (data?.fileName ?? 'file') + '.pdf'
                        downloadLink.click()
                    }
                })
        } else {
            downloadLink.href = file?.file;
            downloadLink.download = (file?.fileName ?? 'file') + '.pdf'
            downloadLink.click()
        }
    }

    const showPdf = () => {
        store.dispatch(openTask({assignmentId: assignment.id}))
    }

    const renderSwitch = (param) => {
        switch (param) {
            case 'Done':
                return {text: 'Розвʼязана правильно', style: {backgroundColor: '#52C41A'}, mainText: ''};
            case 'PartlyDone':
                return {
                    text: 'Не вся задача розвʼязана правильно',
                    style: {backgroundColor: '#FAAD14'},
                    mainText: 'Термін дії задачі пройшов. Ви вже не можете змінити свою відповідь.'
                };
            case 'WronglyDone':
                return {
                    text: 'Розвʼязана неправильно',
                    style: {backgroundColor: '#FF4D4F'},
                    mainText: 'Термін дії задачі пройшов. Ви вже не можете змінити свою відповідь.'
                };
            default:
                return {
                    text: 'Ще не розвʼязана',
                    style: {backgroundColor: '#D9D9D9'},
                    mainText: 'Задача ще не розвʼязана. Введіть відповіді, щоб відправити її на перевірку'
                };
        }
    }

    return (
        <div>
            <div className={css.block}>
                <div ref={leftRef} className={css.left}>
                    <div className={css.mainText}>
                        <div className={css.mainStatus}>
                            <div className={css.dot} style={renderSwitch(assignment?.status).style}></div>
                            <div className={css.status}>{renderSwitch(assignment?.status).text}</div>
                        </div>
                        <div className={`${css.rating} ${css.tooltip}`}>
                            <div className={`${css.star} ${css.tooltip}`}>
                            </div>
                            <div className={`${css.starRating}`}>
                                {assignment.difficulty}
                            </div>
                            <span className={css.toolTipText}>
                                <div className={css.popupText}>
                                Складність задачі
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className={css.name}>{assignment.title}</div>
                    <div className={css.answer}>
                        <div>
                            <div className={css.answerText}>Відповідь</div>
                            <div className={css.answerStatus}>{renderSwitch(assignment?.status).mainText}</div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(submit)} className="w-100">
                        <div className={css.inputs}>
                            {assignment.answers.map((answer, index) =>
                                <InputAnswer key={answer.id} register={register} answer={answer} index={index}
                                             status={assignment?.answers[index]?.resultType} value={assignment?.answers[index]?.value}/>)}
                        </div>
                        {assignment?.status === 'Done' ? <div style={{marginBottom:'24px'}}></div> :
                            <div className={css.sendButton}>
                                <button type={"submit"} className={css.button}>
                                    <div className={css.arrow}>
                                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_180151_1095)">
                                                <path
                                                    d="M14.0534 6.79793L0.983083 0.244802C0.929958 0.218239 0.86902 0.211989 0.811208 0.226052C0.747045 0.241914 0.691781 0.282554 0.657517 0.339075C0.623253 0.395595 0.612782 0.46339 0.628395 0.527614L1.97527 6.03074C1.99558 6.11355 2.05652 6.18074 2.13777 6.2073L4.44558 6.99949L2.13933 7.79168C2.05808 7.8198 1.99715 7.88543 1.9784 7.96824L0.628395 13.4792C0.614333 13.537 0.620583 13.5979 0.647145 13.6495C0.708083 13.7729 0.858083 13.8229 0.983083 13.762L14.0534 7.24636C14.1018 7.22293 14.1409 7.1823 14.1659 7.13543C14.2268 7.01043 14.1768 6.86043 14.0534 6.79793ZM2.16902 11.9136L2.95496 8.70105L7.56746 7.11824C7.60339 7.10574 7.63308 7.07761 7.64558 7.04011C7.66746 6.97449 7.63308 6.90418 7.56746 6.88074L2.95496 5.29949L2.17215 2.09949L11.9846 7.0198L2.16902 11.9136Z"
                                                    fill="black" fillOpacity="1"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_180151_1095">
                                                    <rect width="14" height="14" fill="white"
                                                          transform="translate(0.5)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    < div className={css.forLabel}>Відправити відповідь</div>
                                </button>
                            </div>
                        }
                    </form>
                    <div className={css.author}>
                        {assignment.authorName && <div className={css.authorName}>{assignment.authorName}</div>}
                        {assignment.authorDescription && <div className={css.task}>{assignment.authorDescription}</div>}
                    </div>
                </div>
                <div ref={rightRef} className={css.photo}>
                    <div className={css.hiden}>
                    </div>
                    <button className={css.downloadButton} onClick={downloadPdf}>
                        <div><img src={downloadButton} className={css.imgButton} alt={'no image'}/></div>
                    </button>
                    <button className={css.lookTask}>
                        <div className={css.textButton}
                             onClick={showPdf}>
                            <img src={eye} className={css.eye} alt={'no image'}/>
                            <div>Переглянути задачу</div>
                        </div>
                    </button>
                    <div className={css.imagePdf}>
                        <img style={{width: '100%'}} src={pdfDefaultImages[num]} alt={'pdfDefaultImages'}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Task}