import React, {useEffect, useState} from 'react';

import css from "./Button.module.css";
import cssArchive from "../../../../ArchivePage/Elements/Task/Task.module.css";

const Button = ({button, onClick, disabled}) => {

    const [buttonType, setButtonType] = useState(null)
    useEffect(() => {
        if (button && button === 'left') {
            setButtonType('left')
        } else {
            setButtonType('right')
        }
    }, []);

    if (buttonType === 'left') {
        return (
            <>
                <div>
                    <button disabled={!Boolean(disabled)}
                            className={`${css.turnLeft} ${css.tooltip} ${css.tooltipLeft}`}
                            onClick={() => onClick()}>
                        <div className={css.arrow}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.6254 5.41113H2.48322L7.9551 0.661133C8.0426 0.58457 7.98947 0.442383 7.87385 0.442383H6.49104C6.4301 0.442383 6.37229 0.464258 6.32697 0.50332L0.422286 5.62676C0.368206 5.67364 0.324834 5.73159 0.295109 5.79669C0.265384 5.8618 0.25 5.93253 0.25 6.0041C0.25 6.07567 0.265384 6.1464 0.295109 6.21151C0.324834 6.27661 0.368206 6.33457 0.422286 6.38145L6.36135 11.5361C6.38479 11.5564 6.41291 11.5674 6.4426 11.5674H7.87229C7.98791 11.5674 8.04104 11.4236 7.95354 11.3486L2.48322 6.59863H11.6254C11.6942 6.59863 11.7504 6.54238 11.7504 6.47363V5.53613C11.7504 5.46738 11.6942 5.41113 11.6254 5.41113Z"
                                    fill="black" fillOpacity="0.85"/>
                            </svg>
                        </div>
                        <span className={css.toolTipText}>
                        <div className={cssArchive.popupText} style={{textAlign: "center"}}>
                            Попередня задача
                        </div>
                    </span>
                    </button>
                </div>
            </>
        );
    } else if (buttonType === 'right') {
        return (
            <>
                <div>
                    <button disabled={!Boolean(disabled)}
                            className={`${css.turnLeft} ${css.tooltip} ${css.tooltipRight}`} onClick={() => onClick()}>
                        <div className={css.arrow}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.5781 5.62676L5.675 0.50332C5.62969 0.464258 5.57188 0.442383 5.51094 0.442383H4.12813C4.0125 0.442383 3.95938 0.586133 4.04688 0.661133L9.51875 5.41113H0.375C0.30625 5.41113 0.25 5.46738 0.25 5.53613V6.47363C0.25 6.54238 0.30625 6.59863 0.375 6.59863H9.51719L4.04531 11.3486C3.95781 11.4252 4.01094 11.5674 4.12656 11.5674H5.55625C5.58594 11.5674 5.61563 11.5564 5.6375 11.5361L11.5781 6.38301C11.6322 6.33597 11.6756 6.27788 11.7053 6.21265C11.735 6.14741 11.7504 6.07657 11.7504 6.00488C11.7504 5.9332 11.735 5.86235 11.7053 5.79712C11.6756 5.73189 11.6322 5.6738 11.5781 5.62676Z"
                                    fill="black" fillOpacity="0.85"/>
                            </svg>
                        </div>
                        <span className={css.toolTipText}>
                        <div className={cssArchive.popupText} style={{textAlign: "center"}}>
                            Наступна задача
                        </div>
                    </span>
                    </button>
                </div>
            </>
        )
    } else {
        return (<></>)
    }
};

export default Button;