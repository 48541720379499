import React from 'react';

import logo from './login.svg'

const LoginLogo = () => {
    return (
        <>
            <img style={{width: '67px', height: '52px', marginRight:'2px'}} src={logo} alt={'logo'}/>
        </>
    );
};

export {LoginLogo}