import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from "./User.module.css";
import {selectUser} from "../../../../../store/slices/userSlice";
import {UserPhotos} from "../../../../Elements";
import {day} from "../../../TasksPage/Elements/Task/Task";

const User = ({user}) => {

        const {id, fullName, scoreMath, scorePhysics} = user;
        const {selectedUser, selectedSubject} = useSelector(state => state.user);
        const dispatch = useDispatch();
        const arr = ['', ' бал', ' бали', ' балів'];

        const userScore = selectedSubject === 'all' ? scoreMath + scorePhysics : (selectedSubject === 'math' ? scoreMath : scorePhysics)

        return (
            <div className={css.userBlock} onClick={() => dispatch(selectUser({user}))}>
                <div className={`${css.size} ${selectedUser?.id === id ? css.selectUser : ''}`}>
                    <div className={css.user}>
                        <div className={css.relative}>
                            <div className={css.text}>{user.place}</div>
                            <div className={css.hexagon}></div>
                        </div>
                        <div className={css.blocks}>
                            <UserPhotos id={id} fullName={fullName}/>
                        </div>
                        <div className={`${css.points} ${css.fullName}`}>{fullName}</div>
                    </div>
                    <div className={css.user}>
                        <div className={`${css.points} ${css.text} text-end`}>{day(userScore, arr)}</div>
                    </div>
                </div>
            </div>
        );
    }
;

export {User};