import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {User} from "../User/User";
import {Top3Users} from "../Top3Users/Top3Users";
import {getAllUsers, selectUser} from "../../../../../store/slices/userSlice";

const Users = () => {

    const authUser = useSelector(state => state.auth);
    const {sortedUsers, sortedUsersForTop3} = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllUsers()).then((data) => {
            if (Array.isArray(data.payload)) {
                const currentUser = data.payload.find(x => x.id === authUser.user.id)
                dispatch(selectUser({user: currentUser}))
            }
        })
    }, []);

    return (
        <>
            {!!sortedUsersForTop3?.length && <Top3Users users={sortedUsersForTop3}/>}
            <div>
                {!!sortedUsers.length && sortedUsers.map((user, num) => <User key={num} user={user} number={num + 4}/>)}
            </div>
        </>
    );
};

export {Users};