import React, {useEffect, useRef, useState} from 'react';
import {Link, NavLink, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import css from "./AdminLayout.module.css";
import {LoginLogo, Modal, SettingsModalAdmin, TwoPnkLogo} from "../../Elements";
import cssMain from '../layout.module.css'
import {logoutUser} from "../../../store/slices/authSlice";
import {CreateOrEditModal} from "../../Pages/AdminPages/AdminTasksPage/CreateOrEditModal/CreateOrEditModal";

const AdminLayout = () => {

    const {isSettingsOpen} = useSelector(state => state.modal);

    const initialState = {
        rectColor: "#D9D9D9",
        pathColor: "#000000"
    }
    const [colors, setColors] = useState(initialState);
    const dispatch = useDispatch();

    const changeColor = () => {
        setColors({
            rectColor: "#FF0D9A",
            pathColor: "#FF0D9A"
        });
        document.getElementById('dropdownProfile').style.display = "block";
    };

    const resetColor = () => {
        setColors(initialState)
        document.getElementById('dropdownProfile').style.display = "none";
    }

    const userRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userRef.current && !userRef.current.contains(event.target)) {
                resetColor();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const {assignmentToEdit} = useSelector(state => state.modal);

    return (
        <div className={cssMain.defaultLayout}>
            {assignmentToEdit && <CreateOrEditModal/>}
            <Modal/>
            {isSettingsOpen && <SettingsModalAdmin/>}
            <div className={cssMain.backgroundBase}></div>
            <div style={{ width: '100%', height: '72px' }}></div>
            <header className={["d-flex justify-content-between align-items-stretch", cssMain.header].join(' ')}>
                <div className={cssMain.logo}>
                    <NavLink to={"/"}><LoginLogo/> <TwoPnkLogo/></NavLink>
                </div>
                <div className="d-flex gap-4">
                    <div className={cssMain.menuItem}>
                        <NavLink className={cssMain.headerLinkItem} to="admin/tasks">
                            <div>
                                Задачі
                            </div>
                        </NavLink>
                    </div>
                    <div className={cssMain.menuItem}>
                        <NavLink className={cssMain.headerLinkItem} to="admin/users">
                            <div>
                                Користувачі
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className="d-flex gap-3 align-items-center position-relative">
                    <div onMouseEnter={changeColor} ref={userRef}>
                        <svg width="32" height="34" viewBox="0 0 32 34"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_179458_85814)">
                                <rect width="32" height="32" rx="16" fill="white"/>
                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke={colors.rectColor}/>
                                <path
                                    d="M12.9201 21H11.6275L15.3661 10.8182H16.6388L20.3775 21H19.0849L16.0423 12.429H15.9627L12.9201 21ZM13.3974 17.0227H18.6076V18.1165H13.3974V17.0227Z"
                                    fill={colors.pathColor}/>
                                <g clipPath="url(#clip0_179458_85814)">
                                    <rect width="14" height="14" transform="translate(9 9)" fill="white"/>
                                    <path
                                        d="M21.4141 19.9293C21.1194 19.2311 20.6916 18.597 20.1547 18.0621C19.6195 17.5257 18.9854 17.0981 18.2875 16.8027C18.2813 16.7996 18.275 16.798 18.2688 16.7949C19.2422 16.0918 19.875 14.9465 19.875 13.6543C19.875 11.5137 18.1407 9.7793 16 9.7793C13.8594 9.7793 12.125 11.5137 12.125 13.6543C12.125 14.9465 12.7579 16.0918 13.7313 16.7965C13.725 16.7996 13.7188 16.8012 13.7125 16.8043C13.0125 17.0996 12.3844 17.523 11.8454 18.0637C11.309 18.5989 10.8813 19.233 10.586 19.9309C10.2959 20.6141 10.1394 21.3466 10.125 22.0887C10.1246 22.1053 10.1275 22.1219 10.1336 22.1375C10.1397 22.153 10.1489 22.1671 10.1605 22.1791C10.1722 22.191 10.1861 22.2005 10.2015 22.207C10.2168 22.2135 10.2334 22.2168 10.25 22.2168H11.1875C11.2563 22.2168 11.311 22.1621 11.3125 22.0949C11.3438 20.8887 11.8282 19.759 12.6844 18.9027C13.5704 18.0168 14.7469 17.5293 16 17.5293C17.2532 17.5293 18.4297 18.0168 19.3157 18.9027C20.1719 19.759 20.6563 20.8887 20.6875 22.0949C20.6891 22.1637 20.7438 22.2168 20.8125 22.2168H21.75C21.7667 22.2168 21.7832 22.2135 21.7986 22.207C21.814 22.2005 21.8279 22.191 21.8396 22.1791C21.8512 22.1671 21.8603 22.153 21.8664 22.1375C21.8725 22.1219 21.8755 22.1053 21.875 22.0887C21.8594 21.3418 21.7047 20.6152 21.4141 19.9293ZM16 16.3418C15.2829 16.3418 14.6079 16.0621 14.1 15.5543C13.5922 15.0465 13.3125 14.3715 13.3125 13.6543C13.3125 12.9371 13.5922 12.2621 14.1 11.7543C14.6079 11.2465 15.2829 10.9668 16 10.9668C16.7172 10.9668 17.3922 11.2465 17.9 11.7543C18.4079 12.2621 18.6875 12.9371 18.6875 13.6543C18.6875 14.3715 18.4079 15.0465 17.9 15.5543C17.3922 16.0621 16.7172 16.3418 16 16.3418Z"
                                        fill={colors.pathColor}/>
                                </g>
                            </g>
                            <defs>
                                <filter id="filter0_d_179458_85814" x="0" y="0" width="32" height="34"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                   result="hardAlpha"/>
                                    <feOffset dy="2"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                             result="effect1_dropShadow_179458_85814"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_179458_85814"
                                             result="shape"/>
                                </filter>
                                <clipPath id="clip0_179458_85814">
                                    <rect width="14" height="14" fill="white" transform="translate(9 9)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div onMouseLeave={resetColor} className={css.dropdownProfile} id={'dropdownProfile'}>
                            <Link onClick={() => dispatch(logoutUser())} className="dropdown-profile-exit"
                                  to={"/"}>Вийти</Link>
                        </div>
                    </div>
                </div>
            </header>

            <div className={cssMain.bodyData}>
                <Outlet/>
            </div>

        </div>
    );
};

export {AdminLayout};