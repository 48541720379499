import React, {useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";

import css from './CreateOrEditModal.module.css';
import {closeCreateOrEdit, openModal} from "../../../../../store/slices/modalSlice";
import {Answer} from "./Answer/Answer";
import {assignmentService} from "../../../../../services/assignment.service";
import {ConvertBase64, GetGuid} from "../../../../../Helpers";
import {getAllAssignments} from "../../../../../store/slices/adminSlice";

const CreateOrEditModal = () => {
    const {isCreateOrEditOpen, isCreateMode, assignmentToEdit} = useSelector(state => state.modal);
    const {themes} = useSelector(state => state.task);
    const [selectedThemes, setSelectedThemes] = useState([])
    const [selectedSubject, setSelectedSubject] = useState(null)
    const dispatch = useDispatch();
    const modalRef = useRef(null);
    const [answersList, setAnswersList] = useState([]);
    const [isCreate, setIsCreate] = useState();

    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        reset,
        formState: {
            errors: errors
        },
        setError
    } = useForm({
        defaultValues: {
            ...assignmentToEdit ?? {},
            assignmentThemesIds: assignmentToEdit?.assignmentThemesIds?.map(String) ?? [],
            answers: assignmentToEdit?.answers?.map(x => {
                return {...x, newId: GetGuid()}
            })
        }
    });

    useEffect(() => {
        if (themes && themes.length && assignmentToEdit?.subject !== 'Physics') {
            setSelectedSubject('Math')
        } else {
            setSelectedSubject('Physics')
        }
        setAnswersList(getValues('answers'))
        setIsCreate(isCreateMode);

    }, [assignmentToEdit]);

    useEffect(() => {
        setSelectedThemes(themes.filter(x => x.subject === selectedSubject))
    }, [selectedSubject, themes]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && modalRef.current === event.target) {
                handleCloseModal();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        reset();
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCloseModal = () => {
        dispatch(closeCreateOrEdit());
        reset();
    };

    const openPDF = (e) => {
        e.target.style.cursor = 'wait';
        e.target.disabled = true;

        assignmentService.getFile(assignmentToEdit.id)
            .then(x => {
                const byteCharacters = atob(x?.file.replace('data:application/pdf;base64,', ''));
                const byteArray = Uint8Array.from(byteCharacters, c => c.charCodeAt(0))
                const file = new Blob([byteArray], {type: 'application/pdf;base64'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                e.target.style.cursor = 'auto';
                e.target.disabled = false;
            })
            .catch(res => {
                if (!!res?.response?.data?.message) {
                    dispatch(openModal({message: res?.response?.data?.message, class: 'error'}));
                }
                e.target.style.cursor = 'auto';
            })
    }

    const validateFile = (file) => {
        if (file.length !== 0) {
            if (file[0].size > 1024 * 1024) {
                return "Файл занадто великий. Максимальний розмір: 1MB.";
            }
        }else if (isCreate){
            if (!file || file.length === 0){
                return "Напевно забули завантажити файл"
            }
        }
        return true;
    };

    const submit = async (data) => {
        if (data.file?.length) {
            data.file = await ConvertBase64(data.file[0])
        } else {
            data.file = null;
        }

        if (isCreate) {
            assignmentService.create(data)
                .then(response => {
                    setIsCreate(false);
                    dispatch(openModal({message: 'Задачу створено', class: 'success'}));
                    dispatch(getAllAssignments());
                    reset(response)
                })
                .catch(response => {
                    console.log(response)
                    dispatch(openModal({message: response.response.data.message, class: 'error'}));
                    if (response.response.data?.errors) {
                        console.log(response.response.data?.errors)
                        for (const key of response.response.data.errors) {
                            if (!assignmentToEdit[key.field.toLowerCase()]) {
                                console.log(assignmentToEdit[key.field.toLowerCase()])
                                setError('error', {type: 'server', message: key.message})
                            } else {
                                setError(key.field, {type: 'server', message: key.message})
                            }
                        }
                    }
                })
        } else {
            assignmentService.update(data)
                .then(response => {
                    dispatch(openModal({message: 'Задачу оновлено', class: 'success'}));
                    dispatch(getAllAssignments());
                })
                .catch(response => {
                    console.log(response)
                    dispatch(openModal({message: response.response.data.message, class: 'error'}));
                    if (response.response.data?.errors) {
                        for (const key of response.response.data.errors) {
                            if (!assignmentToEdit[key.field.toLowerCase()]) {
                                setError('error', {type: 'server', message: key.message})
                            } else {
                                setError(key.field, {type: 'server', message: key.message})
                            }
                        }
                    }
                })
        }
    }

    const recalculate = (assignmentId) => {
        assignmentService.updateScore({assignmentId})
            .then(value => {
                if (value.status === 'Success') {
                    dispatch(openModal({message: value.message, class: 'success'}));
                }
            })
            .catch(res => {
                if (!!res?.response?.data?.message) {
                    dispatch(openModal({message: res.response.data.message, class: 'error'}));
                }
            })
    }

    const deleteTask = (assignmentId) => {
        if (window.confirm('Ви впевнені що хочете видалити задачу ' + assignmentId + '?')) {
            assignmentService.deleteAssignment({data:{assignmentId: assignmentId}})
                .then(value => {
                    if (value.status === 'Success') {
                        dispatch(openModal({message: value.message, class: 'success'}));
                        dispatch(getAllAssignments());
                        handleCloseModal()
                        reset()
                    }
                })
                .catch(res => {
                    if (!!res?.response?.data?.message) {
                        dispatch(openModal({message: res.response.data.message, class: 'error'}));
                    }
                })
        }
    }

    return (
        <div className={`${css.overlay} ${Boolean(isCreateOrEditOpen) && css.showing}`} id={"overlay"}
             ref={modalRef}>
            <div className={`${css.modal}`}>
                <div className={css.header}>
                    <h1 className={css.title}>{isCreate ? 'Створення задачі' : 'Редагування'}</h1>
                    {!isCreate && <button type={'button'} onClick={() => deleteTask(assignmentToEdit.id)}
                             className={css.myButton2}>Видалити задачу
                    </button>}
                    <div onClick={handleCloseModal} className={css.close}>
                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.8095 6.00391L9.91106 1.11484C9.97981 1.03359 9.922 0.910156 9.81575 0.910156H8.56888C8.49544 0.910156 8.42512 0.942969 8.37669 0.999219L4.99388 5.03203L1.61106 0.999219C1.56419 0.942969 1.49388 0.910156 1.41888 0.910156H0.172C0.0657503 0.910156 0.00793765 1.03359 0.0766877 1.11484L4.17825 6.00391L0.0766877 10.893C0.061287 10.9111 0.0514069 10.9332 0.04822 10.9568C0.0450331 10.9803 0.0486732 11.0043 0.0587085 11.0259C0.0687439 11.0474 0.0847529 11.0656 0.104835 11.0784C0.124916 11.0911 0.148227 11.0978 0.172 11.0977H1.41888C1.49231 11.0977 1.56263 11.0648 1.61106 11.0086L4.99388 6.97578L8.37669 11.0086C8.42356 11.0648 8.49388 11.0977 8.56888 11.0977H9.81575C9.922 11.0977 9.97981 10.9742 9.91106 10.893L5.8095 6.00391Z"
                                fill="black" fillOpacity="0.85"/>
                        </svg>
                    </div>
                </div>
                <div className={css.modalBody}>
                    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column gap-3">
                        <div className="d-flex gap-5">
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Заголовок</label>
                                <input type="text"
                                       {...register("title", {
                                           required: 'Це поле обов\'язкове',
                                           maxLength: {
                                               value: 250,
                                               message: 'Максимальна довжина 250 символів'
                                           },
                                       })}
                                />
                                {errors.title && <p className={css.errorMessage}>{errors.title?.message}</p>}
                            </div>
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Предмет</label>
                                <select id="subjects" name="subjects"
                                        {...register("subject", {
                                            onChange: (e) => {
                                                setSelectedSubject(e.target.value)
                                                setValue('assignmentThemesIds', [])
                                            }
                                        })}
                                >
                                    <option value="Math">Математика</option>
                                    <option value="Physics">Фізика</option>
                                </select>
                            </div>
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Складність</label>
                                <select id="difficulties" name="difficulties"
                                        {...register("difficulty")}
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>
                            <div className="d-flex flex-column justify-content-end">
                                <button className={css.myButton1} type={'button'} onClick={() => recalculate(assignmentToEdit.id)}>Перерахунок
                                    балів за задачу
                                </button>
                            </div>
                        </div>
                        <div className="d-flex gap-5">
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Автор</label>
                                <input type="text"
                                       {...register("authorName", {
                                           maxLength: {
                                               value: 50,
                                               message: 'Максимальна довжина 50 символів'
                                           }
                                       })}
                                />
                                {errors.authorName && <p className={css.errorMessage}>{errors.authorName?.message}</p>}
                            </div>
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Опис автора</label>
                                <input type="text"
                                       {...register("authorDescription", {
                                           maxLength: {
                                               value: 500,
                                               message: 'Максимальна довжина 500 символів'
                                           }
                                       })}

                                />
                                {errors.authorDescription &&
                                    <p className={css.errorMessage}>{errors.authorDescription?.message}</p>}
                            </div>
                        </div>
                        {/*Файл не приходить з бекенду в модельці response*/}
                        <div className="d-flex gap-5">
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Файл</label>
                                <input type="file" accept=".pdf"
                                       {...register("file", {
                                           validate: validateFile
                                       })}
                                />
                                {errors.file && <p className={css.errorMessage}>{errors.file?.message}</p>}
                            </div>
                            {!isCreate &&
                                <div style={{display: "flex", alignItems: "end"}}>
                                    <button className={css.downloadButton} type={'button'} onClick={openPDF}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor"
                                             className="bi bi-download" viewBox="0 0 16 16">
                                            <path
                                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path
                                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                        </svg>
                                    </button>
                                </div>
                            }
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Ім'я файлу</label>
                                <input type="text"
                                       {...register("fileName", {
                                           maxLength: {
                                               value: 120,
                                               message: 'Максимальна довжина 120 символів'
                                           }
                                       })}
                                />
                                {errors.fileName && <p className={css.errorMessage}>{errors.fileName?.message}</p>}
                            </div>
                        </div>
                        <div className="d-flex gap-5">
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Дата початку</label>
                                <input
                                    type="datetime-local"
                                    id="meeting-time"
                                    min="2018-06-07T00:00:00"
                                    max="2030-06-14T00:00:00"
                                    step="1"
                                    {...register("startDate")}
                                />
                            </div>
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Дата кінця</label>
                                <input
                                    type="datetime-local"
                                    id="meeting-time"
                                    min="2018-06-07T00:00:00"
                                    max="2030-06-14T00:00:00"
                                    step="1"
                                    {...register("endDate")}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <label className={css.myLabel}>Опис задачі</label>
                            <textarea
                                id="description"
                                rows="2"
                                cols="50"
                                maxLength="250"
                                placeholder="Введіть опис задачі"
                                {...register("description", {
                                    maxLength: {
                                        value: 500,
                                        message: 'Максимальна довжина 500 символів'
                                    }
                                })}
                            >
                            </textarea>
                            {errors.description && <p className={css.errorMessage}>{errors.description?.message}</p>}
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column">
                                <label className={css.myLabel}>Теми</label>
                                {selectedThemes && selectedThemes.map((theme, index) => {
                                    return (
                                        <div key={index}>
                                            <input
                                                className={css.assignmentThemesIdsCheckbox}
                                                type={'checkbox'}
                                                value={theme.id}
                                                {...register("assignmentThemesIds")}
                                            />
                                            <label className={css.assignmentThemesIdsLabel}>{theme.value}</label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={css.answers}>
                                <label className={css.myLabel}>Відповіді</label>
                                <div className={css.description}>
                                    <div className={css.desc}>Відповідь</div>
                                    <div className={css.desc}>Макс.бал</div>
                                    <div className={css.desc}>Опис</div>
                                </div>
                                {answersList && answersList.map((answer, index) => {
                                        return (
                                            <div key={index}>

                                                <Answer key={index} answersList={answersList} setValue={setValue}
                                                        answer={answer} setAnswersList={setAnswersList} index={index}
                                                        register={register}/>
                                            </div>
                                        )
                                    }
                                )}

                                <button className={`${css.buttonPlus} ${css.button}`} type="button" onClick={() => {
                                    const arr = [...answersList, {
                                        newId: GetGuid(),
                                        answer: 0,
                                        maxScore: 10,
                                        description: 'text'
                                    }]
                                    setAnswersList(arr)
                                    setValue('answers', arr)
                                }}>+
                                </button>
                            </div>
                        </div>
                        <button className={css.myButton} type={"submit"}>Зберегти</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export {CreateOrEditModal};
