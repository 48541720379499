import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DataTable from 'react-data-table-component';

import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import {titles} from "../../../../configs/titles";
import {getAllAssignments} from "../../../../store/slices/adminSlice";
import {FilterComponent} from "./FilterComponent/FilterComponent";
import {openCreateOrEdit} from "../../../../store/slices/modalSlice";
import {getThemes} from "../../../../store/slices/taskSlice";

const AdminTasksPage = () => {
    useDocumentTitle(titles.admin)
    const { assignments } = useSelector(state => state.admin);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getAllAssignments());
        dispatch(getThemes());
    }, [dispatch]);

    const columns = [
        {
            name: 'Заголовок',
            selector: row => <div title={row.title}>{row.title}</div>,
            sortable: true
        },
        {
            name: 'Опис',
            selector: row => <div title={row.description}>{row.description}</div>,
            sortable: true,
        },
        {
            name: 'Предмет',
            selector: row => row.subject,
            sortable: true,
            width: '120px'
        },
        {
            name: 'Складність',
            selector: row => row.difficulty,
            sortable: true,
            width: '140px'
        },
        {
            name: 'Автор',
            selector: row => row.authorName,
            sortable: true,
        },
        {
            name: 'Період',
            selector: row => row.period,
            sortable: true,
            width: '120px'
        },
        {
            name: 'Дата початку',
            selector: row => <div title={row.startDate}>{row.startDate.replace('T', ' ')}</div>,
            sortable: true,
        },
        {
            name: 'Дата кінця',
            selector: row => <div title={row.endDate}>{row.endDate.replace('T', ' ')}</div>,
            sortable: true,
        },
    ];
    
    const conditionalRowStyles = [
        {
            when: row => 3 < row.period || new Date(row.endDate) < new Date(), 
            style: row => ({ backgroundColor: '#ffdbdb' }),
        },
        {
            when: row => new Date() < new Date(row.startDate),
            style: row => ({ backgroundColor: '#e7efff' }),
        },
        {
            when: row => new Date(row.startDate) <= new Date()  && new Date() <= new Date(row.endDate),
            style: row => ({ backgroundColor: '#e2ffe0' }),
        },
    ];

    const customStyles = {
        rows: {
            style: {
                border: '1px solid transparent',
                '&:hover': {
                    border: '1px #bd0175 solid',
                }
            },
        },
        headCells: {
            style: {
                backgroundColor: '#FAFAFA'
            },
        },
    };

    const filteredItems = assignments.filter(
        item => item.id && (Object.values(item)).join(',').toLowerCase().includes(filterText.toLowerCase()),
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);
    
    return (
        <div>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                responsive
                pointerOnHover
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                paginationResetDefaultPage={resetPaginationToggle}
                conditionalRowStyles={conditionalRowStyles}
                customStyles={customStyles}
                onRowClicked={(row, e) => dispatch(openCreateOrEdit({
                    isCreateMode: false,
                    assignment: row
                }))}
                persistTableHead
            />
        </div>
    );
};

export {AdminTasksPage};