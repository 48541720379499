import React from 'react';
import {useSelector} from "react-redux";

import css from './userDescription.module.css';
import {UserPhotos} from "../../../../Elements";
import {day} from "../../../TasksPage/Elements/Task/Task";

const UserDescription = () => {

    const {selectedUser, selectedSubject} = useSelector(state => state.user);
    const userScore = selectedSubject === 'all' ?  selectedUser?.scoreMath + selectedUser?.scorePhysics : (selectedSubject === 'math' ? selectedUser?.scoreMath : selectedUser?.scorePhysics)

    const arr = ['', ' бал', ' бали', ' балів'];

    return (
        <div>
            <div className={css.main}>
                <div className={css.forBorder}>
                    <div className={css.userTop}>
                        <div className={css.wrap}>
                            {selectedUser &&
                                <UserPhotos id={selectedUser.id} fullName={selectedUser.fullName}/>
                            }
                        </div>
                        <div className={css.userInfo}>
                            <div>
                                <div
                                    className={css.userName}>{selectedUser?.fullName}</div>
                            </div>
                            <div className={css.scoreAndRating}>
                                <div className={css.scoreAndRating}>{day(userScore, arr)}</div>
                                <div>•</div>
                                <div className={css.scoreAndRating}>{selectedUser?.place} місце</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css.forBorderBot}>
                    <div className={css.description}>
                        <div className={css.scoreAndRating}>Опис профілю</div>
                        <div
                            className={css.descrText}>{!selectedUser?.description ? 'Користувач не надав опису профілю' : selectedUser?.description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {UserDescription};