import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useDispatch} from "react-redux";

import css from "./login.module.css";
import cssBase from "../loginBasePage.module.css";
import {EyePassword, LoginLogo, TwoPnkLogo} from "../../../Elements";
import {LoginValidator} from "../../../../validators/user.validator";
import {userService} from "../../../../services/user.service";
import {loginUser, logoutUser} from "../../../../store/slices/authSlice";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import {titles} from "../../../../configs/titles";
import {openModal} from "../../../../store/slices/modalSlice";

const Login = () => {

    useDocumentTitle(titles.login);

    const {
        handleSubmit,
        register,
        setError,
        clearErrors,
        formState: {errors}
    } = useForm({
        resolver: joiResolver(LoginValidator),
        mode: "onTouched"
    });
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordShow, setIsPasswordShow] = useState(false)
    const [emailConfirmButton, setEmailConfirmButton] = useState(null)

    useEffect(() => {
        dispatch(logoutUser())
    }, [])

    const submit = (data) => {
        setIsLoading(true)
        userService.login(data)
            .then(response => {
                dispatch(loginUser(response))
                navigate('/')
            })
            .catch(e => {
                if (!!e?.response?.data?.status) {
                    if (e.response.data.status === 'EmailConfirmError') {
                        setEmailConfirmButton({email: e.response.data.value, text: e.response.data.message})
                    } else if (e.response.data.status === 'LockoutError') {
                        setError('error', {type: 'server', message: e.response.data.message})
                    } else {
                        setError('error', {type: 'server', message: e.response.data.message})
                    }
                }
            })
            .finally(() => setIsLoading(false))
    }

    const resendEmail = () => {
        setEmailConfirmButton({...emailConfirmButton, disabled:true})
        userService.resendVerify({email:emailConfirmButton.email})
            .then(value => {
                if (value.status === 'Success') {
                    dispatch(openModal({class:'success', message: 'Лист на підтвердження був надісланий знову, якщо лист не потрапив у головну папку, перевірте, будь ласка, папку спам'}));
                }
            })
            .catch(res => {
                if (!!res?.response?.data?.message) {
                    dispatch(openModal({message: res?.response?.data?.message, class: 'error'}));
                }
            })
            .finally(() => setEmailConfirmButton(null))
    }

    return (
        <>
            <div className={cssBase.leftPanelTopTextBox}>
                <h1 className={cssBase.leftPanelTopText}>Ввійдіть</h1>
                <h1 className={cssBase.leftPanelTopText} style={{marginBottom: '30px'}}>в <LoginLogo/> <TwoPnkLogo/>
                </h1>
            </div>
            <form onSubmit={handleSubmit(submit)} className={cssBase.formBox}>
                {errors.error && (
                    <p className={css.errorClass} onClick={() => clearErrors('error')}>{errors.error.message}</p>)}
                {emailConfirmButton &&
                    <button className={cssBase.buttonEnter}
                            disabled={Boolean(emailConfirmButton.disabled)}
                            onClick={() => resendEmail()}>
                        {Boolean(emailConfirmButton.disabled) ? 'Loading...' : emailConfirmButton.text}
                    </button>}
                <label className={cssBase.labelText}>Логін або пошта</label>
                <div className={cssBase.formElement}>
                    <input
                        className={errors.UserNameOrEmail ? cssBase.inputClassError : cssBase.inputClass}
                        id="floatingInputUsername"
                        type="text"
                        placeholder="Введіть логін або пошту"
                        {...register("UserNameOrEmail")}
                    />
                </div>
                <p className={css.errorClass}
                   style={{minHeight: '24px'}}>{errors.UserNameOrEmail && errors.UserNameOrEmail.message}</p>

                <label className={cssBase.labelText}>Пароль</label>
                <div className={cssBase.passwordContainer}>
                    <input
                        className={errors.password ? cssBase.inputClassError : cssBase.inputClass}
                        id="floatingConfirmPassword"
                        type={isPasswordShow ? 'text' : 'password'}
                        placeholder="Введіть пароль"
                        {...register("password")}
                    />
                    <div className={cssBase.inputClassEie} onClick={() => setIsPasswordShow(!isPasswordShow)}>
                        <EyePassword isOpen={isPasswordShow}/>
                    </div>
                </div>
                <p className={css.errorClass}>{errors.password && errors.password.message}</p>

                <button className={cssBase.buttonEnter} type="submit"
                        disabled={isLoading}>{isLoading ? 'Loading...' : 'Ввійти'}</button>
            </form>
            <button className={cssBase.buttonRegister} onClick={() => navigate('/user/register')}>Зареєструватись
            </button>
            <div className={'d-flex justify-content-center mt-3'}>
                <Link className={cssBase.linkClass} to={"/user/password/recovery"}>Забули пароль?</Link>
            </div>
        </>
    );
};

export {Login};