import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from "./SearchCheckbox.module.css";
import {
    deleteSortedTopicId,
    setSortedTopicId,
    setSorting
} from "../../../../../../store/slices/taskSlice";

const SearchCheckbox = ({theme}) => {

    const dispatch = useDispatch();
    const {sortedTopicId} = useSelector(state => state.task);
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (sortedTopicId.some(x => x === theme.id.toString())) {
            setChecked(true)
        }else {
            setChecked(false)
        }
    }, [sortedTopicId]);

    const handleRadioChange = (event) => {
        if (event.target.checked) {
            setChecked(true)
            dispatch(setSortedTopicId(event.target.value))
        } else {
            setChecked(false)
            dispatch(deleteSortedTopicId(event.target.value))
        }
        dispatch(setSorting());
    }

    return (
        <>
            <div className={css.forContainer}>
                <label className={`${css.container} ${css['colorContainer' + (theme.id % 10 + 1)]}`}>
                    {theme.value}
                    <input
                        type={'checkbox'}
                        name="checkBox"
                        value={theme.id}
                        checked={checked}
                        onChange={handleRadioChange}
                    />
                    <span className={`${css.checkmark} ${css['colorCheckmark' + (theme.id % 10 + 1)]}`}></span>
                </label>
            </div>
        </>
    );
};

export {SearchCheckbox};