import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

import {userService} from "../../../services/user.service";
import {ParseQueryString} from "../../../Helpers/ParseQueryString";
import {store} from "../../../store";
import {openModal} from "../../../store/slices/modalSlice";
import css from "./emailConfirm.module.css";
import emailHexagon from "../../../assets/images/emailHexagon.svg";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {titles} from "../../../configs/titles";

const EmailConfirm = () => {

    const model = ParseQueryString(window.location.search)
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();

    useDocumentTitle(titles.emailConfirm);

    const submit = (confirmEmail) => {
        setButtonDisabled(true);
        if (!model.token || !model.email) {
            store.dispatch(openModal({message: 'Не вірна ссилка підтвердження пошти', class: 'error'}));
        } else {
            userService.confirmEmail(model)
                .then(value => {
                    store.dispatch(openModal({message: value.message, class: 'success'}));
                    navigate("/user/login");
                })
                .catch(error => {
                    store.dispatch(openModal({message: error.response.data?.errors[0]?.message, class: 'error'}));
                })
        }
    }

    return (
        <div className={css.afterPage}>
            <div className={css.message}>
                <div>
                    <img className={css.messageLogo} src={emailHexagon} alt="Email Logo"/>
                </div>
                <p>
                    <h2><b>Підтвердіть<br/>електронну пошту</b></h2>
                    <div className={css.content}><p>Ви перейшли на сторінку підтвердження електроної пошти. Щоб
                        продовжити, натисніть на кнопку:</p></div>
                </p>
                <button onClick={submit} {...(isButtonDisabled && {disabled: true})}
                        className={css.buttonEnter}>Продовжити
                </button>
            </div>

        </div>
    );
};

export {EmailConfirm} ;
