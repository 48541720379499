import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Task} from "../Task/Task";
import css from './Tasks.module.css';
import {Slide} from '../Slide/Slide';
import {getActiveTasks} from "../../../../../store/slices/taskSlice";

const Tasks = ({state}) => {
    const {activeTasks} = useSelector(state => state.task);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getActiveTasks())
    }, [activeTasks]);

    return (
        <div className={css.main}>
            <div className={css.forTasks}>
                <div className={css.tasks}>
                    <Slide>
                        {activeTasks && activeTasks.filter(x => x.subject.toLowerCase() === state?.subject.toLowerCase()).map(task => <Task key={task.id} assignment={task} content={task.title}/>)}
                    </Slide>
                </div>
            </div>
        </div>
    );
};

export {Tasks};