import React, {useEffect, useState} from 'react';

import css from "./GoToTop.module.css";

const GoToTop = () => {
    const [showArrow, setShowArrow] = useState(false);

    const handleGoToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const handleShowArrow = () => {
            window.pageYOffset > 300 ? setShowArrow(true) : setShowArrow(false);
        };
        window.addEventListener('scroll', handleShowArrow);

        return () => {
            window.removeEventListener('scroll', handleShowArrow);
        };
    }, []);

    return (
        <div>
            {showArrow ? (
            <div className={css.body} onClick={handleGoToTop}>
                <svg width="32" height="32" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M29.3046 16.3446L15.9694 0.976286C15.8488 0.837223 15.6998 0.725695 15.5324 0.649259C15.365 0.572823 15.1831 0.533264 14.9991 0.533264C14.815 0.533264 14.6331 0.572823 14.4657 0.649259C14.2983 0.725695 14.1493 0.837223 14.0287 0.976286L0.697487 16.3446C0.657203 16.3909 0.631083 16.4478 0.622238 16.5086C0.613393 16.5693 0.622196 16.6313 0.647599 16.6872C0.673003 16.7431 0.713935 16.7905 0.76552 16.8238C0.817105 16.8571 0.87717 16.8748 0.938559 16.8749H4.19302C4.37784 16.8749 4.55463 16.7946 4.67918 16.654L13.4743 6.51691V29.1415C13.4743 29.3183 13.6189 29.4629 13.7957 29.4629H16.2064C16.3832 29.4629 16.5278 29.3183 16.5278 29.1415V6.51691L25.3229 16.654C25.4435 16.7946 25.6203 16.8749 25.8091 16.8749H29.0636C29.3368 16.8749 29.4854 16.5535 29.3046 16.3446Z"
                        fill="black" fillOpacity="0.85"/>
                </svg>
            </div>
            ) : null}
        </div>
    );
}

export {GoToTop};
