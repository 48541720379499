import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useNavigate} from "react-router-dom";

import {ResetPasswordValidator} from "../../../validators/user.validator";
import {userService} from "../../../services/user.service";
import {ParseQueryString} from "../../../Helpers/ParseQueryString";
import {store} from "../../../store";
import {openModal} from "../../../store/slices/modalSlice";
import css from "./passwordSubmit.module.css";
import cssBase from "../LoginBasePage/loginBasePage.module.css";
import {EyePassword} from "../../Elements";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { titles } from '../../../configs/titles';

const PasswordSubmit = () => {
    useDocumentTitle(titles.confirmPassword);
    const model = ParseQueryString(window.location.search)

    const passwordFields = {
        password: 'password',
        confirmpassword: 'confirmPassword'
    }
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const [isPasswordShow, setIsPasswordShow] = useState(false)
    const [isConfPasswordShow, setConfIsPasswordShow] = useState(false)


    const {
        register,
        setError,
        handleSubmit,
        clearErrors,
        formState: {errors}
    } = useForm({
        defaultValues: {
            token: model.token,
            email: model.email
        },
        resolver: joiResolver(ResetPasswordValidator),
        mode: "onTouched"
    });

    const submit = async (data) => {
        setIsLoading(true)
        userService.resetPassword(data)
            .then(value => {
                if (value.status === 'Success') {
                    store.dispatch(openModal({message: value.message, class: 'success'}));
                    navigate('/')
                }
            })
            .catch(errors => {
                if (!!errors?.response?.data?.errors) {
                    for (const key of errors.response.data.errors) {
                        if (!passwordFields[key.field.toLowerCase()]) {
                            setError('error', {type: 'server', message: key.message})
                        } else {
                            setError(passwordFields[key.field.toLowerCase()], {type: 'server', message: key.message})
                        }
                    }
                }
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <div className={cssBase.leftPanelTopTextBox}>
                <h1 className={cssBase.leftPanelTopText} style={{marginBottom: '30px'}}>Створіть новий пароль</h1>
            </div>
            <form onSubmit={handleSubmit(submit)} className={cssBase.formBox}>
                {errors.error && (
                    <p className={css.errorClass} onClick={() => clearErrors('error')}>{errors.error.message}</p>)}
                <label className={cssBase.labelText}>Новий пароль:</label>
                <div className={cssBase.formElement}>
                    <div className={cssBase.passwordContainer}>
                        <input className={errors.password ? cssBase.inputClassError : cssBase.inputClass}
                               id="floatingPassword"
                               type={isPasswordShow ? 'text' : 'password'}
                               placeholder="Введіть новий пароль"
                               {...register(passwordFields.password)}
                        />
                        <div className={cssBase.inputClassEie} onClick={() => setIsPasswordShow(!isPasswordShow)}>
                            <EyePassword isOpen={isPasswordShow}/>
                        </div>
                    </div>
                    <div style={{marginTop: '16px'}} className={cssBase.passwordContainer}>
                        <input
                            className={errors.confirmpassword ? cssBase.inputClassError : cssBase.inputClass}
                            id="floatingConfirmPassword"
                            type={isConfPasswordShow ? 'text' : 'password'}
                            placeholder="Підтвердіть новий пароль"
                            {...register(passwordFields.confirmpassword)}
                        />
                        <div className={cssBase.inputClassEie}
                             onClick={() => setConfIsPasswordShow(!isConfPasswordShow)}>
                            <EyePassword isOpen={isConfPasswordShow}/>
                        </div>
                    </div>
                </div>
                <p style={{height: '72px'}}
                   className={css.errorClass}>{errors.password && errors.password.message}<br/>{errors[passwordFields.confirmpassword] && errors[passwordFields.confirmpassword].message}
                </p>
                <button className={cssBase.buttonEnter} type="submit"
                        disabled={isLoading}>{isLoading ? 'Завантаження...' : 'Змінити пароль'}</button>
            </form>
        </>
    );
};

export {PasswordSubmit};