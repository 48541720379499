import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from "./Topic.module.css";
import Search from "./Search/Search";
import {SearchCheckbox} from "./SearchCheckbox/SearchCheckbox";
import {getThemes} from "../../../../../store/slices/taskSlice";

const Topic = () => {
    const {sortedThemes} = useSelector(state => state.task);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getThemes())
    }, []);

    return (
        <div className={css.body}>
            <div className={css.main}>
                <div className={css.mainText}>Тема</div>
                <div><Search /></div>
                <div className={css.margin}>
                    {Boolean(sortedThemes && sortedThemes?.length) && sortedThemes.map((theme, index) =>
                        <SearchCheckbox key={index + 1} theme={theme}/>
                    )}
                </div>
            </div>
        </div>
    );
};

export {Topic};