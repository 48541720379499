import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import AtomicSpinner from "atomic-spinner";

import css from "../../Pages/TasksPage/Elements/Task/Task.module.css";
import {getTaskFile, saveAssignmentFile} from "../../../store/slices/taskSlice";
import {PdfDocument} from "../PdfDocument/PdfDocument";

const AssignmentFile = ({assignmentId, width}) => {

    const {tasksFile} = useSelector(state => state.task);
    const [assignmentFile, setAssignmentFile] = useState(null)
    const [pdfWidth, setPdfWidth] = useState( 100)
    const dispatch = useDispatch();

    useEffect(() => {
        const file = tasksFile.find(x => x.assignmentId === assignmentId)
        if (!file) {
            const defaultFile = {assignmentId, default: true}
            setAssignmentFile(defaultFile)
            dispatch(saveAssignmentFile(defaultFile))
            dispatch(getTaskFile({assignmentId}))
                .then(({payload : data}) => {
                    setAssignmentFile(data)
                    if(data?.file?.startsWith('data:application/pdf;base64')){
                        dispatch(saveAssignmentFile(data))
                    }
                })
        } else {
            setAssignmentFile(file)
        }
    }, [tasksFile]);

    useEffect(() => {
        if(width !== pdfWidth) setPdfWidth(width)
    }, [width]);

    return (
        <>
            {assignmentFile &&
                (assignmentFile.default ?
                    <div className={css.photo}>
                        <AtomicSpinner atomSize={250}
                                       electronSize={2.5}
                                       electronColorPalette={['#d1007d', '#fd53b9', '#ec0533']}
                                       electronPathColor={'#1e2f31'}
                                       nucleusParticleFillColor={'#3e40e6'}
                                       nucleusParticleBorderColor={'#b12222'}/>
                    </div>
                :
                <PdfDocument pdfBase64={assignmentFile.file} scale={1} height={400} width={pdfWidth} pageNumber={1}/>)
            }
        </>
    );
};

export {AssignmentFile};