import {createSlice} from "@reduxjs/toolkit";
import {userService} from "../../services/user.service";
import {createAsyncThunk, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";

export const getUserPhoto = createAsyncThunk(
    'auth/getUserPhoto',
    async ({id}, {rejectWithValue}) => {
        try {
            return await userService.getPhoto(id);
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        status: "idle", // "idle" | "loading" | "failed"
        accessToken: "",
        user: undefined
    },
    reducers: {
        loginUser: (state, action) => {
            state.accessToken = action.payload.token;
            state.user = action.payload.user;
        },
        updateUser: (state, action) => {
            state.user = {...action.payload};
        },
        updateUserPhoto: (state, action) => {
            state.user.photo = action.payload;
        },
        logoutUser: state => {
            state.user = undefined;
            state.accessToken = undefined;
        },
        setLoading: state => {
            state.status = "loading";
        },
        resetLoading: state => {
            state.status = "idle";
        },
    },
    extraReducers: builder =>
        builder
            .addCase(getUserPhoto.fulfilled, (state, action) => {
                if (!state?.user) {
                    state.user = {};
                }
                state.user.photo = action.payload
            })
            .addMatcher(isPending(), state => {

            })
            .addMatcher(isFulfilled(), state => {

            })
            .addMatcher(isRejected(), (state, action) => {

            })
})

export const {
    loginUser,
    updateUser,
    updateUserPhoto,
    logoutUser,
    setLoading,
    resetLoading
} = authSlice.actions;
export default authSlice.reducer;