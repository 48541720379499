import React from 'react';
import css from "../HomeUnregister.module.css";

const Slot = ({image, bigText, smallText, onClick}) => {
    return (
        <div className={css.slot}>
            <div className={css.container}>
                <img src={image} alt={'item'} style={{width: '64px'}}/>
                <div className={css.bigText}>{bigText}</div>
                <div className={css.smallText}>{smallText}</div>
            </div>
            <div style={{width: '100%'}}>
                {onClick && <button className={css.butRegist} onClick={onClick}>Відкрити</button>}
            </div>
        </div>
    );
};

export {Slot};