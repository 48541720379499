import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import {assignmentService} from "../../services/assignment.service";

export const getArchiveTasks = createAsyncThunk(
    'user/getArchiveTasks',
    async (_, {rejectWithValue, getState}) => {
        const state = getState()
        if (state?.task?.tasks?.length) return state?.task.tasks

        try {
            return (await assignmentService.getArchive()).sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const getActiveTasks = createAsyncThunk(
    'user/getActiveTasks',
    async (_, {rejectWithValue, getState}) => {
        const state = getState()
        if (state?.task?.activeTasks?.length) return state?.task.activeTasks
        try {
            return (await assignmentService.getActive()).sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const getTaskFile = createAsyncThunk(
    'task/getTaskFile',
    async ({assignmentId}, {rejectWithValue, getState}) => {
        try {
            return await assignmentService.getFile(assignmentId);
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const getThemes = createAsyncThunk(
    'task/getThemes',
    async (_, {rejectWithValue, getState}) => {

        const state = getState()
        if (state?.task?.themes?.length) return state?.task.themes

        try {
            return await assignmentService.getThemes();
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const taskSlice = createSlice({
    name: 'task',
    initialState: {
        tasks: [],
        tasksFile: [],
        sortedTasks: [],
        activeTasks: [],
        selectedSubject: 'all',
        selectedStatus: 'all',
        selectedTopic: 'all',
        themes: [],
        sortedThemes: [],
        sortedTopicId: [],
        sortedString: '',
        sortedTopic: '',
    },
    reducers: {
        setSelectedStatus: (state, action) => {
            state.selectedStatus = action.payload;
        },
        setSelectedSubject: (state, action) => {
            state.selectedSubject = action.payload;
        },
        setSortedString: (state, action) => {
            state.sortedString = action.payload;
        },
        setSortedTopic: (state, action) => {
            state.sortedTopic = action.payload
        },
        setSortedTopicId: (state, action) => {
            if (!state.sortedTopicId.some(x => x === action.payload)) {
                state.sortedTopicId.push(action.payload)
            }
        },
        deleteSortedTopicId: (state, action) => {
            if (state.sortedTopicId.some(x => x === action.payload)) {
                state.sortedTopicId = state.sortedTopicId.filter(x => x !== action.payload)
            }
        },
        setSorting: (state, action) => {
            const subject = state.selectedSubject
            const status = state.selectedStatus
            const sortedString = state.sortedString
            const sortedTopic = state.sortedTopic
            let sorted = []

            if (sortedTopic) {
                state.sortedThemes = state.themes.filter(theme =>
                    theme.value.toLowerCase().includes(sortedTopic.toLowerCase()))
            } else {
                state.sortedThemes = state.themes.filter(x => x.subject.toLowerCase())
            }

            if (subject === 'math') {
                sorted = state.tasks.filter(x => x.subject.toLowerCase() === 'math')
                state.sortedThemes = state.sortedThemes.filter(x => x.subject.toLowerCase() === 'math')
            } else if (subject === 'physics') {
                sorted = state.tasks.filter(x => x.subject.toLowerCase() === 'physics')
                state.sortedThemes = state.sortedThemes.filter(x => x.subject.toLowerCase() === 'physics')
            } else {
                sorted = state.tasks
            }

            state.sortedTopicId = state.sortedTopicId.filter(x => state.sortedThemes.some(y => y.id.toString() === x))
            state.sortedTasks = sorted

            if (status === 'all') {
                sorted = state.sortedTasks
            } else if (status === 'done') {
                sorted = state.sortedTasks.filter(x => x.status.toLowerCase() === 'done')
            } else {
                sorted = state.sortedTasks.filter(x => x.status.toLowerCase() !== 'done')
            }

            state.sortedTasks = sorted

            if (state.sortedTopicId.length > 0) {
                state.sortedTasks = state.sortedTasks.filter(task =>
                    state.sortedTopicId.every(topicId =>
                        task.assignmentThemesIds.some( x => x.toString() === topicId)
                    ))
            }

            if (sortedString) {
                state.sortedTasks = state.sortedTasks.filter(task =>
                    task.title.toLowerCase().includes(sortedString.toLowerCase()))
            }
        },
        saveAssignmentFile: (state, action) => {
            if (state.tasksFile.some(x => x.assignmentId === action.payload.assignmentId)) {
                state.tasksFile = state.tasksFile.filter(x => x.assignmentId !== action.payload.assignmentId)
            }
            state.tasksFile.push(action.payload)
        },
        deleteTasks:(state)=>{
                state.activeTasks = [];
                state.tasks = [];
                state.sortedTasks=[];
        }
    },
    extraReducers: builder =>
        builder
            .addCase(getArchiveTasks.fulfilled, (state, action) => {
                state.tasks = action.payload
                state.sortedTasks = action.payload
            })
            .addCase(getActiveTasks.fulfilled, (state, action) => {
                state.activeTasks = action.payload
            })
            .addCase(getThemes.fulfilled, (state, action) => {
                state.themes = action.payload
                state.sortedThemes = action.payload
            })
            .addCase(getTaskFile.fulfilled, (state, action) => {

            })
            .addMatcher(isPending(), state => {

            })
            .addMatcher(isFulfilled(), state => {

            })
            .addMatcher(isRejected(), (state, action) => {
            })
})

export const {
    setSelectedStatus,
    setSelectedSubject,
    setSortedString,
    setSortedTopic,
    setSortedTopicId,
    setSorting,
    deleteSortedTopicId,
    saveAssignmentFile,
    deleteTasks,
} = taskSlice.actions;
export default taskSlice.reducer;