import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from "./slices/authSlice";
import modalReducer from "./slices/modalSlice";
import homeReducer from "./slices/homeSlice";
import userSlice from "./slices/userSlice";
import taskSlice from "./slices/taskSlice";
import adminSlice from "./slices/adminSlice";

const rootReducer = combineReducers({
    modal: modalReducer,
    auth: authReducer,
    home: homeReducer,
    user: userSlice,
    task: taskSlice,
    admin: adminSlice
})

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['modal', 'home', 'user', 'task', 'admin']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            },
        }),
})

export const persistor = persistStore(store);