import React from 'react';

import two_pnk from './2πnk.svg'

const TwoPnkLogo = () => {
    return (
        <>
            <img src={two_pnk} alt={'2pnk'}/>
        </>
    );
};

export {TwoPnkLogo}