import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from './Tasks.module.css';
import {Task} from "../Task/Task";
import {getArchiveTasks, setSorting} from "../../../../../store/slices/taskSlice";


const Tasks = () => {
    const {tasks, sortedTasks} = useSelector(state => state.task);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!tasks?.length) {
            dispatch(getArchiveTasks()).then(() => dispatch(setSorting()))
        }
    }, []);

    return (
        <div className={css.main}>
            {sortedTasks && sortedTasks.map((task, index) => <Task key={task.id} num={(index + 1) % 4}
                                                                   assignment={task}/>)}
        </div>
    );
};

export {Tasks};