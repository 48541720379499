import React, {useState} from 'react';
import {useDispatch} from "react-redux";

import css from './Search.module.css';
import {setSortedTopic, setSorting} from "../../../../../../store/slices/taskSlice";

const Search = () => {
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();

    const submit = (event) => {
        const searchTextValue = event.target.value.trim();
        setSearchText(searchTextValue);
        dispatch(setSortedTopic(searchTextValue))
        dispatch(setSorting())
    }

    return (
        <div>
            <div className={css.main}>
                <input type={'text'} className={css.search} onChange={submit} placeholder='Пошук теми'
                       value={searchText}/>
                <div style={{position: "absolute", top: 0}}>
                    <div className={css.searchItem}></div>
                </div>
            </div>
        </div>
    );
};

export default Search;