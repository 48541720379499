import React from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Alert, Button, Stack} from "react-bootstrap";

import {logoutUser} from "../../../store/slices/authSlice";

const LogoutPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const back = () => navigate(-1);
    const logout = () => {
        dispatch(logoutUser());
        navigate('/');
    }
    
    return (
        <div>
            <Stack gap={2} className="col-sm-2 mx-auto">
                <Alert key={'warning'} variant={'warning'}>
                    Ви справді хочете вийти з акаунту?
                </Alert>
                <Stack direction="horizontal" className={"justify-content-between"}>
                    <Button onClick={logout} variant="success">Підтвердити</Button>
                    <Button onClick={back} variant="danger">Назад</Button>
                </Stack>
            </Stack>
        </div>
    );
};

export {LogoutPage};