import React, {useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";

import "../../../services/axios.service";
import css from './SettingsModal.module.css';
import {closeSettings, hideSettings, openModal} from "../../../store/slices/modalSlice";
import {EyePassword} from "../EyePassword/EyePassword";
import {updateUser, updateUserPhoto} from "../../../store/slices/authSlice";
import {userService} from "../../../services/user.service";
import {ConvertBase64} from "../../../Helpers";
import {UserPhotos} from "../UserPhotos/UserPhotos";
import {deleteUsersDefaultPhoto} from "../../../store/slices/userSlice";

const SettingsModal = () => {

    const {isSettingsOpen, isSettingsShow} = useSelector(state => state.modal);
    const {user} = useSelector(state => state.auth);
    const [isCurrentPasswordShow, setIsCurrentPasswordShow] = useState(false);
    const [isNewPasswordShow, setIsNewPasswordShow] = useState(false);
    const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [passwordBtnDisabled, setPasswordBtnDisabled] = useState(true)
    const [timerId1, setTimerId1] = useState();
    const [timerId2, setTimerId2] = useState();
    const [waitCursor, setWaitCursor] = useState(false);
    const [descriptionLength, setDescriptionLength] = useState(user.description?.length || 0)
    const dispatch = useDispatch();
    const [passwordValidStatus, setPasswordValidStatus] = useState({
        oldPassword: false,
        newpassword: false,
        confirmPassword: false
    })
    const modalRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && modalRef.current === event.target) {
                handleCloseModal();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleCloseModal = () => {
        setTimeout(() => dispatch(hideSettings()), 600);
        dispatch(closeSettings())
        resetForm2();
        setIsCurrentPasswordShow(false);
        setIsNewPasswordShow(false);
        setIsConfirmPasswordShow(false);
    };


    const userPassword =
        {
            id: 'id',
            oldpassword: 'oldPassword',
            newpassword: 'newpassword',
            confirmpassword: 'confirmPassword'
        };

    const userPhoto =
        {
            id: 'id',
            login: 'login',
            photo: 'photo',
            fullName: 'fullName',
            description: 'description'
        };

    const {
        register: registerForm1,
        handleSubmit: handleSubmitForm1,
        formState: {errors: errorsForm1},
        setError: setErrorForm1,
        trigger: triggerForm1
    } = useForm({
        defaultValues: {
            userId: user.id
        }
    });

    const {
        register: registerForm2,
        handleSubmit: handleSubmitForm2,
        formState: {errors: errorsForm2},
        getValues: getValuesForm2,
        reset: resetForm2,
        setError: setErrorForm2,
        trigger: triggerPass
    } = useForm({
        defaultValues: {
            userId: user.id
        }
    });

    const {
        register: registerForm3,
        handleSubmit: handleSubmitForm3,
        formState: {errors: errorsForm3},
    } = useForm({
        defaultValues: {
            userId: user.id
        }
    });

    const submitUserPassword = async (userPasswordModel) => {
        userService.updateUserPassword(userPasswordModel)
            .then(value => {
                dispatch(openModal({message: value.message, class: 'success'}));
            })
            .catch(response => {
                if (!!response?.response?.data?.errors) {
                    for (const key of response.response.data.errors) {
                        dispatch(openModal({message: key.message ?? key.description, class: 'error'}));
                    }
                }else{
                    dispatch(openModal({message: response?.response?.data?.message, class: 'error'}));
                }
            })
    }

    const submitPhoto = async (data) => {
        if (data.photo?.length) {
            data.photo = await ConvertBase64(data.photo[0])
        } else {
            data.photo = null;
        }
        userService.updatePhoto(data)
            .then(value => {
                if (value.status === 'Success') {
                    console.log(value?.value)
                    dispatch(updateUserPhoto(value?.value.photo))
                    dispatch(deleteUsersDefaultPhoto(data.userId))
                    dispatch(openModal({message: value?.message, class: 'success'}));
                }
            })
            .catch(response => {
                if (!!response?.response?.data?.errors) {
                    for (const key of response.response.data.errors) {
                        if (!userPhoto[key.field.toLowerCase()]) {
                            setErrorForm1('error', {type: 'server', message: key.message})
                        } else {
                            setErrorForm1(userPhoto[key.field.toLowerCase()], {type: 'server', message: key.message})
                        }
                    }
                }
            })
    }

    const submitInfo = async (updateUserData) => {
        userService.updateUser(updateUserData)
            .then(value => {
                if (value.status === 'Success') {
                    console.log(value?.value)
                    dispatch(updateUser(value?.value))
                    dispatch(openModal({message: value?.message, class: 'success'}));
                }
            })
            .catch(response => {
                if (!!response?.response?.data?.errors) {
                    for (const key of response.response.data.errors) {
                        if (!userPhoto[key.field.toLowerCase()]) {
                            setErrorForm1('error', {type: 'server', message: key.message})
                        } else {
                            setErrorForm1(userPhoto[key.field.toLowerCase()], {type: 'server', message: key.message})
                        }
                    }
                }
            })
            .finally(() => setWaitCursor(false))
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            dispatch(updateUserPhoto(null))
        }
        setSelectedFile(file);
        handlePhotoChange();
    };

    const validateFile = (file) => {
        if (file.length !== 0) {
            if (file[0].size > 1024 * 256) {
                return "Файл занадто великий. Максимальний розмір: 250KB.";
            }
        }
        return true;
    };

    const isLoginAvailable = async (nickname) => {
        if (nickname === user.login) return true;
        const {isSuccess, messages} = await userService.checkNickname(nickname)
        return isSuccess || messages
    }

    const handlePhotoChange = () => {
        clearTimeout(timerId2);
        setTimerId2(setTimeout(() => {
            handleSubmitForm3(submitPhoto)()
        }, 3000))
    }

    const handleInputChange = () => {
        clearTimeout(timerId1);
        setWaitCursor(true)
        setTimerId1(setTimeout(() => {
            handleSubmitForm1(submitInfo)()
        }, 3000))
    }

    const startTrigger = async (obj, name) => {
        passwordValidStatus[name] = await triggerPass(name)
        setPasswordValidStatus(passwordValidStatus)
        if (passwordValidStatus.oldPassword && passwordValidStatus.newpassword && passwordValidStatus.confirmPassword) {
            setPasswordBtnDisabled(false);
        } else {
            setPasswordBtnDisabled(true);
        }
    }

    return (
        <div className={`${css.overlay} ${Boolean(isSettingsOpen && isSettingsShow) && css.showing}`} id={"overlay"}
             ref={modalRef}>
            <div className={`${css.modal}`} style={{cursor: waitCursor ? 'wait' : 'inherit'}}>
                <div className={css.header}>
                    <h1 className={css.title}>Налаштування</h1>
                    <div onClick={handleCloseModal} className={css.close}>
                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.8095 6.00391L9.91106 1.11484C9.97981 1.03359 9.922 0.910156 9.81575 0.910156H8.56888C8.49544 0.910156 8.42512 0.942969 8.37669 0.999219L4.99388 5.03203L1.61106 0.999219C1.56419 0.942969 1.49388 0.910156 1.41888 0.910156H0.172C0.0657503 0.910156 0.00793765 1.03359 0.0766877 1.11484L4.17825 6.00391L0.0766877 10.893C0.061287 10.9111 0.0514069 10.9332 0.04822 10.9568C0.0450331 10.9803 0.0486732 11.0043 0.0587085 11.0259C0.0687439 11.0474 0.0847529 11.0656 0.104835 11.0784C0.124916 11.0911 0.148227 11.0978 0.172 11.0977H1.41888C1.49231 11.0977 1.56263 11.0648 1.61106 11.0086L4.99388 6.97578L8.37669 11.0086C8.42356 11.0648 8.49388 11.0977 8.56888 11.0977H9.81575C9.922 11.0977 9.97981 10.9742 9.91106 10.893L5.8095 6.00391Z"
                                fill="black" fillOpacity="0.85"/>
                        </svg>
                    </div>
                </div>
                <div className={css.modalBody}>
                    <form onSubmit={handleSubmitForm3(submitPhoto)} id="form3">
                        <div className={`d-flex align-items-center gap-4 position-relative ${css.photoSection}`}>
                            {selectedFile ?
                                <img src={URL.createObjectURL(selectedFile)}
                                     alt="Завантажена фото"/>
                                :
                                <>
                                    <div className={css.photo}>
                                        <UserPhotos id={user.id} fullName={user.fullName}/>
                                    </div>
                                </>}
                            <div onClick={() => document.getElementById("file-upload").click()}
                                 className={`d-flex align-items-center gap-2 ${css.choosePhotoBtn}`}>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.90156 8.3252C5.91326 8.34014 5.92819 8.35222 5.94525 8.36053C5.96231 8.36884 5.98103 8.37316 6 8.37316C6.01897 8.37316 6.03769 8.36884 6.05475 8.36053C6.0718 8.35222 6.08674 8.34014 6.09844 8.3252L7.84844 6.11113C7.9125 6.02988 7.85469 5.90957 7.75 5.90957H6.59219V0.62207C6.59219 0.55332 6.53594 0.49707 6.46719 0.49707H5.52969C5.46094 0.49707 5.40469 0.55332 5.40469 0.62207V5.90801H4.25C4.14531 5.90801 4.0875 6.02832 4.15156 6.10957L5.90156 8.3252ZM11.7188 7.77832H10.7812C10.7125 7.77832 10.6562 7.83457 10.6562 7.90332V10.3096H1.34375V7.90332C1.34375 7.83457 1.2875 7.77832 1.21875 7.77832H0.28125C0.2125 7.77832 0.15625 7.83457 0.15625 7.90332V10.9971C0.15625 11.2736 0.379687 11.4971 0.65625 11.4971H11.3438C11.6203 11.4971 11.8438 11.2736 11.8438 10.9971V7.90332C11.8438 7.83457 11.7875 7.77832 11.7188 7.77832Z"
                                        fill="black" fillOpacity="0.85"/>
                                </svg>
                                <span>Завантажити нове фото</span>
                                <input
                                    type="file"
                                    id="file-upload"
                                    accept=".jpg, .jpeg, .png"
                                    {...registerForm3("photo", {
                                        onChange: handleFileChange,
                                        validate: validateFile
                                    })}
                                />
                            </div>
                            <button onClick={() => {
                                setSelectedFile(null);
                                document.getElementById("file-upload").value = '';
                                handleSubmitForm3(submitPhoto)();
                            }}
                                    className={`d-flex align-items-center gap-2 ${css.deletePhotoBtn}`}
                                    disabled={!Boolean(user.photo)}
                            >
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_179838_3720)">
                                        <rect width="14" height="14" fill="white" fillOpacity="0.01"/>
                                        <path
                                            d="M4.625 1.87402H4.5C4.56875 1.87402 4.625 1.81777 4.625 1.74902V1.87402H9.375V1.74902C9.375 1.81777 9.43125 1.87402 9.5 1.87402H9.375V2.99902H10.5V1.74902C10.5 1.19746 10.0516 0.749023 9.5 0.749023H4.5C3.94844 0.749023 3.5 1.19746 3.5 1.74902V2.99902H4.625V1.87402ZM12.5 2.99902H1.5C1.22344 2.99902 1 3.22246 1 3.49902V3.99902C1 4.06777 1.05625 4.12402 1.125 4.12402H2.06875L2.45469 12.2959C2.47969 12.8287 2.92031 13.249 3.45313 13.249H10.5469C11.0813 13.249 11.5203 12.8303 11.5453 12.2959L11.9313 4.12402H12.875C12.9438 4.12402 13 4.06777 13 3.99902V3.49902C13 3.22246 12.7766 2.99902 12.5 2.99902ZM10.4266 12.124H3.57344L3.19531 4.12402H10.8047L10.4266 12.124Z"
                                            fill="black" fillOpacity="0.85"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_179838_3720">
                                            <rect width="14" height="14" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span>Видалити фото</span>
                            </button>
                            {errorsForm3.photo && <p style={{maxWidth: "unset", bottom: 0}}
                                                     className={css.errorMessage}>{errorsForm3.photo?.message}</p>}
                        </div>
                    </form>

                    <form onSubmit={handleSubmitForm1(submitInfo)} id={"form1"}>
                        <div className={`d-flex flex-column gap-3 ${css.personalInfoSection}`}>
                            <h2>Особиста інформація</h2>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column position-relative">
                                    <label htmlFor="login">Логін</label>
                                    <input
                                        type="text"
                                        id="login"
                                        placeholder="Введіть логін"
                                        {...registerForm1(userPhoto.login, {
                                            onChange: async () => {
                                                handleInputChange();
                                                await triggerForm1(userPhoto.login)
                                            },
                                            value: user.login,
                                            required: 'Введіть логін користувача',
                                            minLength: {
                                                value: 2,
                                                message: 'Мінімальна довжина 2 символи'
                                            },
                                            pattern: {
                                                value: /^\S+$/,
                                                message: 'Логін не може містити пробіли',
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: 'Максимальна довжина 50 символів'
                                            },
                                            validate: {
                                                isAvailable: async (value) => {
                                                    return await isLoginAvailable(value)
                                                },
                                            }
                                        })}
                                    />
                                    {errorsForm1.login &&
                                    <p className={css.errorMessage}>{errorsForm1.login.message}</p>}
                                </div>
                                <div className="d-flex flex-column position-relative">
                                    <label htmlFor="name">Ім'я</label>
                                    <input
                                        id="name"
                                        placeholder={"Задати ім\'я"}
                                        type="text"
                                        {...registerForm1(userPhoto.fullName, {
                                            onChange: async () => {
                                                handleInputChange();
                                                await triggerForm1(userPhoto.fullName)
                                            },
                                            value: user.fullName,
                                            maxLength: {
                                                value: 64,
                                                message: 'Максимальна кількість символів 64'
                                            },
                                        })}
                                    />
                                    {errorsForm1.fullName &&
                                    <p className={css.errorMessage}>{errorsForm1.fullName.message}</p>}
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="email">Пошта</label>
                                    <input className={css.email} type="email" id="email" value={user.email}
                                           disabled={true} readOnly={true}/>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="description">Опис профілю</label>
                                <textarea
                                    id="description"
                                    name=""
                                    rows="2"
                                    cols="50"
                                    maxLength="250"
                                    placeholder="Введіть опис профілю"
                                    {...registerForm1(userPhoto.description, {
                                        value: user.description,
                                        maxLength: {
                                            value: 251,
                                            message: 'Опис повиннен містити не більше 251 символа'
                                        },
                                        onChange: e => {
                                            handleInputChange()
                                            setDescriptionLength(e.target.value.length)
                                        }
                                    })}
                                >
                                </textarea>
                                <span className={`align-self-end ${css.symbolCounter}`}
                                      id="symbolCounter"> {descriptionLength} / 250</span>
                            </div>
                        </div>
                    </form>

                    <form onSubmit={handleSubmitForm2(submitUserPassword)}
                          className={`d-flex flex-column gap-3 ${css.resetPasswordSection}`}>
                        <h2>Зміна пароля</h2>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column position-relative">
                                <label htmlFor="currentPassword">Поточний пароль</label>
                                <input
                                    type={isCurrentPasswordShow ? 'text' : 'password'}
                                    id="currentPassword"
                                    placeholder="Введіть пароль"
                                    {...registerForm2(userPassword.oldpassword, {
                                        onChange: async (event) => await startTrigger(event.target.value, userPassword.oldpassword),
                                        required: 'Введіть пароль'
                                    })}
                                />
                                <div className={css.passwordEye}
                                     onClick={() => setIsCurrentPasswordShow(!isCurrentPasswordShow)}>
                                    <EyePassword isOpen={isCurrentPasswordShow}/>
                                </div>
                                {(errorsForm2[userPassword.oldpassword]) && (
                                    <p className={css.errorMessage}>
                                        {errorsForm2[userPassword.oldpassword]?.message}
                                    </p>)}
                            </div>
                            <div className="d-flex flex-column position-relative">
                                <label htmlFor="newPassword">Новий пароль</label>
                                <input
                                    type={isNewPasswordShow ? 'text' : 'password'}
                                    id="newPassword"
                                    placeholder="Введіть новий пароль"
                                    {...registerForm2(userPassword.newpassword, {
                                        onChange: async (event) => await startTrigger(event.target.value, userPassword.newpassword),
                                        required: 'Введіть пароль',
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/,
                                            message: 'Пароль не відповідає критеріям',
                                        }
                                    })}
                                />
                                <div className={css.passwordEye}
                                     onClick={() => setIsNewPasswordShow(!isNewPasswordShow)}>
                                    <EyePassword isOpen={isNewPasswordShow}/>
                                </div>
                                {errorsForm2.newpassword && (
                                    <p title="Пароль повинен містити одну малу та велику літеру, одне число та бути в межах 8 та 16 символів"
                                       className={css.errorMessage}>{errorsForm2.newpassword.message}</p>)}
                            </div>
                            <div className="d-flex flex-column position-relative">
                                <label htmlFor="repeatPassword">Підтвердження нового паролю</label>
                                <input
                                    type={isConfirmPasswordShow ? 'text' : 'password'}
                                    id="repeatPassword"
                                    placeholder="Підтвердіть пароль"
                                    {...registerForm2(userPassword.confirmpassword, {
                                        onChange: async (event) => await startTrigger(event.target.value, userPassword.confirmpassword),
                                        required: 'Введіть підтвердження паролю',
                                        validate: (value) => value === getValuesForm2('newpassword') || 'Паролі повинні співпадати'
                                    })}
                                />
                                <div className={css.passwordEye}
                                     onClick={() => setIsConfirmPasswordShow(!isConfirmPasswordShow)}>
                                    <EyePassword isOpen={isConfirmPasswordShow}/>
                                </div>
                                {errorsForm2[userPassword.confirmpassword] && (
                                    <p className={css.errorMessage}>{errorsForm2[userPassword.confirmpassword].message}</p>)}
                            </div>
                        </div>
                        <button type="submit" disabled={passwordBtnDisabled}>Змінити пароль</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export {SettingsModal};