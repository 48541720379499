import React from 'react';

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {titles} from "../../../configs/titles";

const ForbiddenPage = () => {
    useDocumentTitle(titles.forbiddenPage);
    return (
        <div className={'alert-danger'}>
            Forbidden
        </div>
    );
};

export {ForbiddenPage}