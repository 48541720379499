import React from 'react';

import css from "./rightPanelImage.module.css";
import rightPanel from "./loginRightPanel.jpg"

const RightPanelImage = () => {
    return (
        <div className={css.rightPanel}>
            <img className={css.rightPanelImg} src={rightPanel} alt={'left panel'}/>
        </div>
    );
};

export {RightPanelImage};