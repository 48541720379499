import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            NotFound
        </div>
    );
};

export {NotFoundPage};