import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from "../../../RatingsPage/Elements/SubjectAndPeriod.module.css";
import {setSelectedStatus, setSorting} from "../../../../../store/slices/taskSlice";

const Status = () => {
    const dispatch = useDispatch();

    const handleRadioChange = (event) => {
        const newSelectedStatus = event.target.value;
        dispatch(setSelectedStatus(newSelectedStatus));
        dispatch(setSorting());
    };

    const {selectedStatus} = useSelector(state => state.task);

    return (
        <div className={css.body}>
            <div className={css.main}>
                <div className={css.mainText}>Статус задачі</div>
                <div>
                    <label className={css.container}>
                        Всі задачі
                        <input
                            checked={selectedStatus === 'all'}
                            type={'radio'}
                            name="status"
                            value="all"
                            onChange={handleRadioChange}
                        />
                        <span className={css.checkmark}></span>
                    </label>
                    <label className={css.container}>
                        Розв'язані
                        <input
                            checked={selectedStatus === 'done'}
                            type={'radio'}
                            name="status"
                            value="done"
                            onChange={handleRadioChange}
                        />
                        <span className={css.checkmark}></span>
                    </label>
                    <label className={css.container}>
                        Нерозв'язані
                        <input
                            checked={selectedStatus === 'notDone'}
                            type={'radio'}
                            name="status"
                            value="notDone"
                            onChange={handleRadioChange}
                        />
                        <span className={css.checkmark}></span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export {Status};