import axiosService from "./axios.service";
import {urls} from "../configs/urls";

export const userService = {
    resetPassword: (data) => axiosService.post(`${urls.users}/ResetPassword`, data),
    create: (user) => axiosService.post(urls.users,user),
    checkEmail: (email) => axiosService.get(`${urls.users}/email/${email}`),
    checkNickname: (nickname) => axiosService.get(`${urls.users}/nickname/${nickname}`),
    confirmEmail: (model) => axiosService.post(`${urls.users}/ConfirmEmail`, model),    
    createEmailModel: (email) => axiosService.post(`${urls.users}/ForgotPassword`, email),
    login: (data) => axiosService.post(`${urls.authenticate}/login`, data),
    updateUserPassword: (userPassword) => axiosService.patch(`${urls.users}/password`, userPassword),
    updateUser: (userData) => axiosService.patch(urls.users, userData),
    getAll: (isCurrentPeriod) => axiosService.get(`${urls.users}/${isCurrentPeriod}`),
    getPhoto: (id) => axiosService.get(`${urls.users}/${id}/photo`),
    updatePhoto: (data) => axiosService.patch(`${urls.users}/UpdatePhoto`, data),
    resendVerify: (email) => axiosService.post(`${urls.users}/ResendVerify`, email)
}