import React from 'react';

import css from "../CreateOrEditModal.module.css";

const Answer = ({answer,setAnswersList,answersList, setValue, register, index}) => {

    return (
        <div className={css.blockAnswer}>
            <div>{index+1}.</div>
            <button className={`${css.buttonMinus} ${css.button}`}  type={"button"} onClick={() => {
                const  answers = answersList.filter(x => x.newId !== answer.newId)
                console.log(answers)
                setValue('answers', answers)
                setAnswersList(answers)
            }}>-</button>
            <input
                style={{width: "100px"}}
                type="number"
                placeholder="Відповідь"
                step={0.000001}
                {...register(`answers.${index}.answer`, {
                    value:answer.answer,
                    valueAsNumber: true
                })}
            />
            <input
                style={{width: "100px"}}
                type="number"
                placeholder="Кількість балів"
                {...register(`answers.${index}.maxScore`, {
                    value:answer.maxScore,
                    valueAsNumber: true
                })}
            />
            <input
                style={{width: "100px"}}
                type="text"
                placeholder="Опис"
                {...register(`answers.${index}.description`, {
                    value:answer.description
                })}
            />
        </div>
    );
};

export {Answer};