import React, {useEffect, useRef, useState} from 'react';
import AtomicSpinner from 'atomic-spinner'
import {useDispatch, useSelector} from "react-redux";

import css from './taskModal.module.css';
import {closeTask, clearTask} from "../../../store/slices/modalSlice";
import {useDraggable} from "react-use-draggable-scroll";
import {AssignmentFile} from "../AssignmentFile/AssignmentFile";

const TaskModal = () => {
    const dispatch = useDispatch();
    const {isOpenTask, taskContent} = useSelector((state) => state.modal);
    const ref = useRef();
    const {events} = useDraggable(ref);
    const [zoomLevel, setZoomLevel] = useState(250);

    const modalRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && modalRef.current === event.target) {
                handleCloseModal();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleZoomIn = () => {
        setZoomLevel(prevZoom => prevZoom + 100);
    };

    const handleZoomOut = () => {
        setZoomLevel(prevZoom => prevZoom - 100);
    };

    const handleCloseModal = () => {
        dispatch(closeTask());
        setTimeout(() => dispatch(clearTask()), 600)
    };

    useEffect(() => {
        setZoomLevel(ref.current?.clientWidth ?? 550)
    }, [ref, isOpenTask]);

    return (
        <div className={`${css.overlay} ${isOpenTask && css.showing}`} ref={modalRef}>
            <div className={css.modal}>
                <div className={css.main}>
                    <div className={css.zoomButton}>
                        <div onClick={handleZoomIn} className={css.butStyle}>
                            <div className={css.arrow}>
                                <svg width="30" height="30" viewBox="0 0 50 50" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="25" cy="25" r="23" stroke="black" strokeWidth="4"/>
                                    <rect x="12" y="23" width="26" height="4" rx="1" fill="black"/>
                                    <rect x="27" y="12" width="26" height="4" rx="1" transform="rotate(90 27 12)"
                                          fill="black"/>
                                </svg>
                            </div>

                        </div>
                        <div onClick={handleZoomOut} className={css.butStyle}>
                            <div className={css.arrow}>
                                <svg width="30" height="30" viewBox="0 0 50 50" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="25" cy="25" r="23" stroke="black" strokeWidth="4"/>
                                    <rect x="12" y="23" width="26" height="4" rx="1" fill="black"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <span className={css.close} onClick={handleCloseModal}>&times;</span>
                </div>
                <section>
                    <div className={css.scroll}
                         {...events}
                         ref={ref}>
                        <div className={css.pdfImg}>
                            {taskContent?.assignmentId ?
                                <AssignmentFile assignmentId={taskContent.assignmentId} width={zoomLevel}/>
                                :
                                <AtomicSpinner atomSize={250}
                                               electronSize={2.5}
                                               electronColorPalette={['#d1007d', '#fd53b9', '#ec0533']}
                                               electronPathColor={'#1e2f31'}
                                               nucleusParticleFillColor={'#3e40e6'}
                                               nucleusParticleBorderColor={'#b12222'}/>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
        ;
};

export {TaskModal};