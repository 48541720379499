import {createSlice} from "@reduxjs/toolkit";

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        isModalOpen: false,
        modalContent: null,
        isSettingsOpen: false,
        isSettingsShow: false,
        isOpenTask: false,
        taskContent: null,
        isCreateOrEditOpen: false,
        isCreateMode: false,
        assignmentToEdit: null
    },
    reducers: {
        openModal: (state, action) => {
            state.isModalOpen = true;
            state.modalContent = action.payload;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
        clearModal:(state)=>{
            state.modalContent = null;
        },
        openSettings: (state, action) => {
            state.isSettingsOpen = true;
            state.isSettingsShow = true;
        },
        closeSettings: (state) => {
            state.isSettingsOpen = false;
        },
        hideSettings: (state) => {
            state.isSettingsShow = false;
        },
        openTask: (state,action) => {
            state.isOpenTask = true;
            state.taskContent = action.payload;
        },
        closeTask: (state) => {
            state.isOpenTask = false;
        },
        clearTask: (state) => {
            state.taskContent = null;
        },
        openCreateOrEdit: (state, action) => {
            state.isCreateMode = action.payload.isCreateMode;
            state.assignmentToEdit = action.payload.assignment;
            state.isCreateOrEditOpen = true;
        },
        closeCreateOrEdit: (state) => {
            state.assignmentToEdit = null;
            state.isCreateOrEditOpen = false;
        }
      
    },
});
export const {
    clearModal,
    openModal,
    closeModal,
    openSettings,
    closeSettings,
    hideSettings,
    openTask,
    closeTask,
    clearTask,
    openCreateOrEdit,
    closeCreateOrEdit
} = modalSlice.actions;
export default modalSlice.reducer;