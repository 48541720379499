import React from 'react';

import css from './LeftPanel.module.css';
import {Search} from "../Search/Search";
import {Subject} from "../Subject/Subject";
import {Status} from "../Status/Status";
import {Topic} from "../Topic/Topic";

const LeftPanel = () => {
    return (
        <div className={css.main}>
            <Search/>
            <Subject/>
            <Status/>
            <Topic/>
        </div>
    );
};

export {LeftPanel};