import React from 'react';

import {Tasks} from "../Tasks/Tasks";

const RightPanel = () => {
    return (
        <div>
            <Tasks />
        </div>
    );
};

export {RightPanel};