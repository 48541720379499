import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

import css from "./recovery.module.css";
import cssBase from "../loginBasePage.module.css";
import {userService} from "../../../../services/user.service";
import {openModal} from "../../../../store/slices/modalSlice";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import {titles} from "../../../../configs/titles";

const Recovery = () => {
    useDocumentTitle(titles.recovery);
    const {
        trigger,
        handleSubmit,
        register,
        formState: {errors}
    } = useForm();
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const user =
        {
            email: 'email'
        };
    const isEmailExistInDb = async (email) => {
        const {isSuccess} = await userService.checkEmail(email)
        return !isSuccess || 'Не знайдено користувача з даною електронною адресою'
    }

    const startTrigger = async (obj, name) => {
        await trigger(name);
    }

    const submit = (resetEmail) => {
        setIsLoading(true)
        userService.createEmailModel(resetEmail)
            .then(value => {
                if (value.status === 'Success') {
                    dispatch(openModal({message: value.message, class: 'success'}));
                    navigate(-1)
                }
            })
            .catch(res => {
                if (!!res?.response?.data?.message) {
                    dispatch(openModal({message: res.response.data.message, class: 'error'}));
                    setIsLoading(false)
                }
            })

    }
    return (
        <>
            <div className={cssBase.leftPanelTopTextBox}>
                <h1 className={cssBase.leftPanelTopText}>Відновити пароль</h1>
            </div>
            <div className={css.labelText}>
                <div>Якщо ви забули пароль, не хвилюйтесь.</div>
                <div> Ми відправимо інструкцію, як його відновити</div>
            </div>
            <form onSubmit={handleSubmit(submit)} className={cssBase.formBox}>
                <label className={cssBase.labelText}>Пошта:</label>
                <div className={cssBase.formElement}>
                    <input
                        className={errors.email ? cssBase.inputClassError : cssBase.inputClass}
                        {...register(user.email, {
                            onChange: async (event) => await startTrigger(event.target.value, user.email),
                            required: 'Введіть свою електронну адресу',
                            pattern: {
                                value: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
                                message: 'Введено невірну адресу електронної пошти',
                            },
                            validate: {
                                isAvailable: async (value) => {
                                    return await isEmailExistInDb(value)
                                },
                            }
                        })}
                        placeholder={'Введіть пошту'}
                        type={'text'}
                    />
                </div>
                <p className={css.errorClass}
                   style={{minHeight: '24px'}}>{errors.email && errors.email.message}</p>
                <button className={cssBase.buttonEnter} type="submit"
                        disabled={isLoading}>{isLoading ? 'Виконується...' : 'Відновити пароль'}</button>
                <div className={'d-flex justify-content-center mt-3'}>
                    <Link className={cssBase.linkClass} to={"/user/login"}>Повернутись назад</Link>
                </div>
            </form>
        </>
    );
};

export {Recovery};