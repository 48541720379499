import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {GoToTop, Search, User} from "./Elements";
import css from "./AdminUsersPage.module.css";
import {filterUsers, setAllUsers, setFilter1, setFilter2, setFilter3, getAllUsers} from "../../../../store/slices/adminSlice";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import {titles} from "../../../../configs/titles";

const AdminUsersPage = () => {
    useDocumentTitle(titles.admin);
    const dispatch = useDispatch();

    // Get all users initially
    useEffect(() => {
        dispatch(getAllUsers()).then((data) => {
            dispatch(setAllUsers(data.payload));
        })
    }, [dispatch]);

    const allUsers = useSelector(state => state.admin.allUsers);
    const filteredUsers = useSelector(state => state.admin.filteredUsers);

    return (
        <>
            <Search/>
            <div>
                <i>
                    {(filteredUsers.length === allUsers.length) ? 'Загальна кількість користувачів: ' : 'Знайдено збігів: '}
                    {filteredUsers.length}
                </i>
                <div className={css.legend}>
                    <div>
                        <i>Фільтри:</i>
                        <label className={css.confirmed}>
                            <input
                                type="checkbox"
                                id="confirmed"
                                onChange={() => {
                                    dispatch(setFilter1(document.getElementById("confirmed").checked));
                                    dispatch(filterUsers());
                                }}
                            />
                            Активні
                        </label>
                    </div>
                    <div>
                        <label className={css.unconfirmed}>
                            <input
                                type="checkbox"
                                id="unconfirmed"
                                onChange={() => {
                                    dispatch(setFilter2(document.getElementById("unconfirmed").checked));
                                    dispatch(filterUsers());
                                }}
                            />
                            Непідтвержені
                        </label>
                    </div>
                    <div>
                        <label className={css.blocked}>
                            <input
                                type="checkbox"
                                id="blocked"
                                onChange={() => {
                                    dispatch(setFilter3(document.getElementById("blocked").checked));
                                    dispatch(filterUsers());
                                }}
                            />
                            Заблоковані
                        </label>
                    </div>
                </div>
            </div>
            <table className={css.usersHeader}>
                <tbody>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Логін</th>
                    <th>Пошта</th>
                    <th>Ім'я</th>
                    <th>Опис</th>
                </tr>
                </tbody>
            </table>
            {!!filteredUsers.length && filteredUsers.map((user, index) => <User user={user} key={index}/>)}
            <GoToTop/>
        </>
    );
};

export {AdminUsersPage};