import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import "../../../services/axios.service";
import {getAllContent} from "../../../store/slices/homeSlice";
import {store} from "../../../store";
import {openModal} from "../../../store/slices/modalSlice";
import css from './HomePage.module.css';
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {titles} from "../../../configs/titles";

const HomePage = () => {
    useDocumentTitle(titles.homePage);
    const dispatch = useDispatch();
    const {content, status, error} = useSelector(state => state.home);

    useEffect(() => {
        if (content === null) {
            dispatch(getAllContent())
        }
    }, []);

    return (
        <div>
            {status === 'pending' && <h1>Loading...</h1>}
            {error && <h2>{error}</h2>}
            {/*Home*/}
            {/*<p>{content}</p>*/}
            <div className={css.main}>
                <div className={css.top}>
                    <div className={css.mainText}>
                        2
                        <div className={css.mainTextForP}>π</div>
                        nK
                    </div>
                    <div className={css.text}>Мотивуй.Інформуй.Залучай</div>
                </div>
            </div>
            <button onClick={() => store.dispatch(openModal({
                message: 'Щось пішло не так, ми працюємо над цією проблемою',
                class: 'error'
            }))}>Що таке 2πnK?
            </button>

        </div>
    );
};
export {HomePage};