import React from 'react';

import css from './ArchivePage.module.css';
import {LeftPanel} from "./Elements/LeftPanel/LeftPanel";
import {RightPanel} from "./Elements/RightPanel/RightPanel";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {titles} from "../../../configs/titles";

const ArchivePage = () => {
    useDocumentTitle(titles.archive);
    return (
        <div className={css.main}>
            <div className={css.leftPanel}><LeftPanel /></div>
            <div className={css.rightPanel}><RightPanel /></div>
        </div>
    );
};

export {ArchivePage};