import React from 'react';
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

import css from "./User.module.css";
import {UserPhotos} from "../../../../../Elements";
import {openSettings} from "../../../../../../store/slices/modalSlice";
import {selectUser} from "../../../../../../store/slices/adminSlice";

const User = ({user}) => {

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(selectUser(user.id));
        dispatch(openSettings());
    }

    return (
        <Link onClick={() => handleClick()} to={"#"} className={css.userBlock}>
            <div
                 className={`${css.body} ${(!user.emailConfirmStatus && !!user.blockedStatus) ? css.body3 : ''} 
                 ${user.emailConfirmStatus ? '' : css.body1} ${!!user.blockedStatus ? css.body2 : ''}`}
            >
                <div className={css.userPhoto}>
                    <UserPhotos id={user.id} fullName={user.fullName}/>
                </div>
                <table className={css.userTable}>
                    <tbody>
                    <tr>
                        <td>{user.id}</td>
                        <td>{user.login}</td>
                        <td>{user.email}</td>
                        <td>{user.fullName}</td>
                        <td>{user.description}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Link>
    );
    }
;

export {User};