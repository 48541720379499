import React from 'react';
import {Link, useLocation} from "react-router-dom";

import css from './TasksPage.module.css';
import {Tasks} from "./Elements/Tasks/Tasks";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {titles} from "../../../configs/titles";

const TasksPage = () => {

    let {state} = useLocation();
    state ??= {subject: 'math'}
    useDocumentTitle(state?.subject==='math' ? titles.math : titles.physics)

    return (
        <div className={css.main}>
            <div className={css.buttons}>
                <div className={css.subject}>
                    <Link to={""} state={{subject: "math"}}
                          className={state?.subject === 'math' ? css.selectedSubject : css.unSelectedSubject}>
                        <div className={css.math}></div>
                        <div className={css.text}>Математика</div>
                    </Link>
                    <Link to={""} state={{subject: "physics"}}
                          className={state?.subject === 'math' ? css.unSelectedSubject : css.selectedSubject}>
                        <div className={css.physics}></div>
                        <div className={css.text}>Фізика</div>
                    </Link>
                </div>
            </div>
            <Tasks state={state}/>
        </div>
    );
};

export {TasksPage};