import axios from 'axios';
import {store} from "../store";
import {logoutUser} from "../store/slices/authSlice";

const axiosService = axios.create();

axiosService.interceptors.request.use(
    (config) => {

        const token = store.getState().auth?.accessToken
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
);

axiosService.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error.response) {
            if (error.response.status === 403) {
                window.location.href = '/forbidden';
            } else if (error.response.status === 401) {
                store.dispatch(logoutUser())
                window.location.href = '/user/login';
            } else {
                console.error('Error:', error);
                return Promise.reject(error);
            }
        } else {
            console.error('Error:', error);
            return Promise.reject(error);
        }
    }
);

export default axiosService ;