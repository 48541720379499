import axiosService from "./axios.service";
import {urls} from "../configs/urls";

export const assignmentService = {
    getActive: () => axiosService.get(`${urls.assignment}/active`),
    getArchive: () => axiosService.get(`${urls.assignment}/archive`),
    getThemes: () => axiosService.get(`${urls.assignment}/themes`),
    create: (assignment) => axiosService.post(urls.assignment, assignment),
    update: (assignment) => axiosService.patch(urls.assignment, assignment),
    sendAnswer: (result) => axiosService.post(`${urls.assignment}/answer`, result),
    getFile: (assignmentId) => axiosService.get(`${urls.assignment}/${assignmentId}/file`),
    updateScore: (assignmentId) => axiosService.patch(`${urls.assignment}/recalculate`, assignmentId),
    deleteAssignment: (data) => axiosService.delete(`${urls.assignment}/delete`, data)
}