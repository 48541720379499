import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from './Modal.module.css';
import {clearModal, closeModal} from "../../../store/slices/modalSlice";
import hexagonSuccess from './hexagonSuccess.svg'
import hexagonError from './hexagonError.svg'

const Modal = () => {
    const dispatch = useDispatch();
    const {isModalOpen, modalContent} = useSelector((state) => state.modal);

    useEffect(() => {
        if (isModalOpen && !modalContent?.taskSuccess) {
            setTimeout(() => {
                handleCloseModal();
            }, 4000);
        }
    }, [isModalOpen]);

    const handleCloseModal = () => {
        dispatch(closeModal());
        setTimeout(() => dispatch(clearModal()), 600)
    };

    return (
        <>
            {modalContent &&
                <div className={`${css.modal} ${css[modalContent?.class]} ${isModalOpen && css.showing}`}>
                    <span className={css.close} onClick={handleCloseModal}>&times;</span>
                    <div className={css.logo}>
                        <img src={modalContent?.class === 'success' ? hexagonSuccess : hexagonError}
                             alt={'HexagonSuccess'}/>
                    </div>
                    {modalContent?.taskSuccess ?
                        <div>
                            <div className={css.welcome}>Вітаємо! Ви вирішили задачу</div>
                            <div className={css.content}>Ви успішно відправили відповідь на розгляд. Після того, як
                                спливе
                                термін вирішення задачі, ви зможете подивитись відповідь в архіві.
                            </div>
                            <div className={css.centerText}>
                                <button className={css.buttonArchive}>Перейти до архіву</button>
                                <button className={css.buttonRating}>Перейти до рейтингу</button>
                            </div>
                        </div>
                        :
                        <div className={css.modalContent}>
                            <p className={css.textCenter}>{modalContent?.message}</p>
                        </div>
                    }

                </div>
            }
        </>
    );
};

export {Modal};