import React, {useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";

import "../../../services/axios.service";
import css from './SettingsModalAdmin.module.css';
import {closeSettings, openModal} from "../../../store/slices/modalSlice";
import {EyePassword} from "../EyePassword/EyePassword";
import {userService} from "../../../services/user.service";
import {adminService} from "../../../services/admin.service";
import {UserPhotos} from "../UserPhotos/UserPhotos";
import {deleteUsersDefaultPhoto} from "../../../store/slices/userSlice";
import {getAllUsers, setAllUsers, selectUser, filterUsers} from "../../../store/slices/adminSlice";

const SettingsModalAdmin = () => {
    const {isSettingsOpen, isSettingsShow} = useSelector(state => state.modal);
    const user = useSelector(state => state.admin.viewedUser);
    const {usersPhoto} = useSelector(state => state.user);
    const longEnUSFormatter = new Intl.DateTimeFormat('uk-UA', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const lockoutDateTime = longEnUSFormatter.format(new Date(user.blockedStatus));
    const [photoStatus, setPhotoStatus] = useState(true);
    const [isNewPasswordShow, setIsNewPasswordShow] = useState(false);
    const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);
    const [passwordBtnDisabled, setPasswordBtnDisabled] = useState(true)
    const [blockBtnDisabled, setBlockBtnDisabled] = useState(true)
    const [unblockBtnDisabled, setUnblockBtnDisabled] = useState(true)
    const [timerId1, setTimerId1] = useState();
    const [waitCursor, setWaitCursor] = useState(false);
    const [descriptionLength, setDescriptionLength] = useState(user.description?.length || 0)
    const dispatch = useDispatch();
    const [passwordValidStatus, setPasswordValidStatus] = useState({
        oldPassword: false,
        newpassword: false,
        confirmPassword: false
    })
    // Check if user is blocked
    useEffect(() => {
        if (user.blockedStatus === null) {
            setBlockBtnDisabled(false);
        } else {
            setUnblockBtnDisabled(false);
        }
    }, [])

    // Check if user has a photo
    useEffect(() => {
        const userPhoto = usersPhoto.find(x => x.id === user.id)
        if (!userPhoto.data.error) {
            setPhotoStatus(false)
        }
    }, [])

    const modalRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && modalRef.current === event.target) {
                handleCloseModal();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCloseModal = () => {
        dispatch(closeSettings())
        resetForm2();
        setIsNewPasswordShow(false);
        setIsConfirmPasswordShow(false);
    };

    // Closing modal using Esc Key
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handleCloseModal();
            }
        }
        document.addEventListener('keydown', close)
        return () => document.removeEventListener('keydown', close)
    }, [])

    const userPassword =
        {
            id: 'id',
            oldpassword: 'oldPassword',
            newpassword: 'newpassword',
            confirmpassword: 'confirmPassword'
        };

    const userPhoto =
        {
            id: 'id',
            login: 'login',
            photo: 'photo',
            fullName: 'fullName',
            description: 'description'
        };

    const lockoutData =
        {
            userId: user.id
        }

    const {
        register: registerForm1,
        handleSubmit: handleSubmitForm1,
        formState: {errors: errorsForm1},
        setError: setErrorForm1,
        trigger: triggerForm1
    } = useForm({
        defaultValues: {
            userId: user.id
        }
    });

    const {
        register: registerForm2,
        handleSubmit: handleSubmitForm2,
        formState: {errors: errorsForm2},
        getValues: getValuesForm2,
        reset: resetForm2,
        setError: setErrorForm2,
        trigger: triggerPass
    } = useForm({
        defaultValues: {
            userId: user.id
        }
    });

    const {
        handleSubmit: handleSubmitForm3,
        formState: {errors: errorsForm3},
    } = useForm({
        defaultValues: {
            userId: user.id
        }
    });

    const submitUserPassword = async (userPasswordModel) => {
        userPasswordModel.oldPassword = 'Password@123';
        userService.updateUserPassword(userPasswordModel)
            .then(value => {
                dispatch(openModal({message: value.message, class: 'success'}));
            })
            .catch(response => {
                setErrorForm2('error', {type: 'server', message: response.response.data.message})
            })
    }

    const submitPhoto = async (data) => {
        adminService.deleteUserPhoto(data.userId)
            .then(value => {
                if (value.status === 'Success') {
                    console.log(value?.value)
                    dispatch(deleteUsersDefaultPhoto(data.userId))
                    dispatch(openModal({message: value?.message, class: 'success'}));
                }
            })
            .catch(response => {
                setErrorForm1('error', {type: 'server', message: response.response.data.message});
                dispatch(openModal({message: response.response.data.message, class: 'error'}));
            })
    }

    const submitInfo = async (updateUserData) => {
        await userService.updateUser(updateUserData)
            .then(value => {
                if (value.status === 'Success') {
                    console.log(value?.value)
                    dispatch(openModal({message: value?.message, class: 'success'}));
                    dispatch(getAllUsers()).then((data) => {
                        dispatch(setAllUsers(data.payload));
                        dispatch(filterUsers());
                    });
                }
            })
            .catch(response => {
                setErrorForm2('error', {type: 'server', message: response.response.data.message})
                dispatch(openModal({message: response.response.data.message, class: 'error'}));
            })
            .finally(() => setWaitCursor(false))
    }

    const isLoginAvailable = async (nickname) => {
        if (nickname === user.login) return true;
        const {isSuccess, messages} = await userService.checkNickname(nickname)
        return isSuccess || messages
    }

    const handleInputChange = () => {
        clearTimeout(timerId1);
        setWaitCursor(true)
        setTimerId1(setTimeout(() => {
            handleSubmitForm1(submitInfo)()
        }, 3000))
    }

    const startTrigger = async (obj, name) => {
        passwordValidStatus[name] = await triggerPass(name)
        setPasswordValidStatus(passwordValidStatus)
        if (passwordValidStatus.newpassword && passwordValidStatus.confirmPassword) {
            setPasswordBtnDisabled(false);
        } else {
            setPasswordBtnDisabled(true);
        }
    }

    const handleBlock = async (lockoutData) => {
        lockoutData.lockoutStatus = true;
        await adminService.lockoutUser(lockoutData)
            .then(value => {
                setBlockBtnDisabled(true);
                setUnblockBtnDisabled(false);
                dispatch(openModal({message: value.message, class: 'success'}));
                dispatch(getAllUsers()).then((data) => {
                    dispatch(setAllUsers(data.payload));
                    dispatch(filterUsers());
                    dispatch(selectUser(lockoutData.userId));
                });
                console.log(user);
            })
            .catch(response => {
                setErrorForm2('error', {type: 'server', message: response.response.data.message});
                dispatch(openModal({message: response.response.data.message, class: 'error'}));
            });
    }

    const handleUnblock = async (lockoutData) => {
        lockoutData.lockoutStatus = false;
        await adminService.lockoutUser(lockoutData)
            .then(value => {
                setUnblockBtnDisabled(true);
                setBlockBtnDisabled(false);
                dispatch(openModal({message: value.message, class: 'success'}));
                dispatch(getAllUsers()).then((data) => {
                    dispatch(setAllUsers(data.payload));
                    dispatch(filterUsers());
                    dispatch(selectUser(lockoutData.userId));
                });
            })
            .catch(response => {
                setErrorForm2('error', {type: 'server', message: response.response.data.message});
                dispatch(openModal({message: response.response.data.message, class: 'error'}));
            });
    }

    const confirmEmail = (e) => {
        e.preventDefault()
        e.target.disabled = true
        if (user?.email) {
            userService.resendVerify({email: user?.email})
                .then(value => {
                    if (value.status === 'Success') {
                        dispatch(openModal({message: 'Лист на підтвердження був надісланий знову', class: 'success'}));
                    }
                })
                .catch(res => {
                    if (!!res?.response?.data?.message) {
                        dispatch(openModal({message: res?.response?.data?.message, class: 'error'}));
                    }
                })
        }
    }

    return (
        <div className={`${css.overlay} ${Boolean(isSettingsOpen && isSettingsShow) && css.showing}`} id={"overlay"}
             ref={modalRef}>
            <div className={`${css.modal}`} style={{cursor: waitCursor ? 'wait' : 'inherit'}}>
                <div className={css.header}>
                    <h1 className={css.title}>Налаштування</h1>
                    <div onClick={handleCloseModal} className={css.close}>
                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.8095 6.00391L9.91106 1.11484C9.97981 1.03359 9.922 0.910156 9.81575 0.910156H8.56888C8.49544 0.910156 8.42512 0.942969 8.37669 0.999219L4.99388 5.03203L1.61106 0.999219C1.56419 0.942969 1.49388 0.910156 1.41888 0.910156H0.172C0.0657503 0.910156 0.00793765 1.03359 0.0766877 1.11484L4.17825 6.00391L0.0766877 10.893C0.061287 10.9111 0.0514069 10.9332 0.04822 10.9568C0.0450331 10.9803 0.0486732 11.0043 0.0587085 11.0259C0.0687439 11.0474 0.0847529 11.0656 0.104835 11.0784C0.124916 11.0911 0.148227 11.0978 0.172 11.0977H1.41888C1.49231 11.0977 1.56263 11.0648 1.61106 11.0086L4.99388 6.97578L8.37669 11.0086C8.42356 11.0648 8.49388 11.0977 8.56888 11.0977H9.81575C9.922 11.0977 9.97981 10.9742 9.91106 10.893L5.8095 6.00391Z"
                                fill="black" fillOpacity="0.85"/>
                        </svg>
                    </div>
                </div>
                <div className={css.modalBody}>
                    <form onSubmit={handleSubmitForm3(submitPhoto)} id="form3">
                        <div className={`d-flex align-items-center gap-4 position-relative ${css.photoSection}`}>
                            <div className={css.photo}>
                                <UserPhotos id={user.id} fullName={user.fullName}/>
                            </div>
                            <button className={`d-flex align-items-center gap-2 ${css.deletePhotoBtn}`}
                                    disabled={photoStatus}
                            >
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_179838_3720)">
                                        <rect width="14" height="14" fill="white" fillOpacity="0.01"/>
                                        <path
                                            d="M4.625 1.87402H4.5C4.56875 1.87402 4.625 1.81777 4.625 1.74902V1.87402H9.375V1.74902C9.375 1.81777 9.43125 1.87402 9.5 1.87402H9.375V2.99902H10.5V1.74902C10.5 1.19746 10.0516 0.749023 9.5 0.749023H4.5C3.94844 0.749023 3.5 1.19746 3.5 1.74902V2.99902H4.625V1.87402ZM12.5 2.99902H1.5C1.22344 2.99902 1 3.22246 1 3.49902V3.99902C1 4.06777 1.05625 4.12402 1.125 4.12402H2.06875L2.45469 12.2959C2.47969 12.8287 2.92031 13.249 3.45313 13.249H10.5469C11.0813 13.249 11.5203 12.8303 11.5453 12.2959L11.9313 4.12402H12.875C12.9438 4.12402 13 4.06777 13 3.99902V3.49902C13 3.22246 12.7766 2.99902 12.5 2.99902ZM10.4266 12.124H3.57344L3.19531 4.12402H10.8047L10.4266 12.124Z"
                                            fill="black" fillOpacity="0.85"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_179838_3720">
                                            <rect width="14" height="14" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span>Видалити фото</span>
                            </button>

                            {user && !user.emailConfirmStatus &&
                                <button className={`d-flex align-items-center gap-2 ${css.confirmEmailBtn}`}
                                        type={'button'}
                                        onClick={confirmEmail}
                                        title={'Не використовуйте цю кнопку часто, реакція на кнопку може бути на протязі 10 секунд'}>
                                    Повторно надіслати підтвердження емейлу
                                </button>}

                            {errorsForm3.photo && <p style={{maxWidth: "unset", bottom: 0}}
                                                     className={css.errorMessage}>{errorsForm3.photo?.message}</p>}
                        </div>
                    </form>

                    <form onSubmit={handleSubmitForm1(submitInfo)} id={"form1"}>
                        <div className={`d-flex flex-column gap-3 ${css.personalInfoSection}`}>
                            <h2>Особиста інформація користувача <i>(ID: {user.id})</i></h2>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column position-relative">
                                    <label htmlFor="login">Логін</label>
                                    <input
                                        type="text"
                                        id="login"
                                        placeholder="Введіть логін"
                                        {...registerForm1(userPhoto.login, {
                                            onChange: async () => {
                                                handleInputChange();
                                                await triggerForm1(userPhoto.login)
                                            },
                                            value: user.login,
                                            required: 'Введіть логін користувача',
                                            minLength: {
                                                value: 2,
                                                message: 'Мінімальна довжина 2 символи'
                                            },
                                            pattern: {
                                                value: /^\S+$/,
                                                message: 'Логін не може містити пробіли',
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: 'Максимальна довжина 50 символів'
                                            },
                                            validate: {
                                                isAvailable: async (value) => {
                                                    return await isLoginAvailable(value)
                                                },
                                            }
                                        })}
                                    />
                                    {errorsForm1.login &&
                                        <p className={css.errorMessage}>{errorsForm1.login.message}</p>}
                                </div>
                                <div className="d-flex flex-column position-relative">
                                    <label htmlFor="name">Ім'я</label>
                                    <input
                                        id="name"
                                        placeholder={"Задати ім'я"}
                                        type="text"
                                        {...registerForm1(userPhoto.fullName, {
                                            onChange: async () => {
                                                handleInputChange();
                                                await triggerForm1(userPhoto.fullName)
                                            },
                                            value: user.fullName,
                                            maxLength: {
                                                value: 64,
                                                message: 'Максимальна кількість символів 64'
                                            },
                                        })}
                                    />
                                    {errorsForm1.fullName &&
                                        <p className={css.errorMessage}>{errorsForm1.fullName.message}</p>}
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="email">Пошта</label>
                                    <input type="text" id="email" value={user.email} disabled={true} readOnly={true}/>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="description">Опис профілю</label>
                                <textarea
                                    id="description"
                                    name=""
                                    rows="2"
                                    cols="50"
                                    maxLength="250"
                                    placeholder="Введіть опис профілю"
                                    {...registerForm1(userPhoto.description, {
                                        value: user.description,
                                        maxLength: {
                                            value: 251,
                                            message: 'Опис повиннен містити не більше 251 символа'
                                        },
                                        onChange: e => {
                                            handleInputChange()
                                            setDescriptionLength(e.target.value.length)
                                        }
                                    })}
                                >
                                </textarea>
                                <span className={`align-self-end ${css.symbolCounter}`}
                                      id="symbolCounter"> {descriptionLength} / 250</span>
                            </div>
                        </div>
                    </form>

                    <form onSubmit={handleSubmitForm2(submitUserPassword)}
                          className={`d-flex flex-column gap-3 ${css.resetPasswordSection}`}>
                        <h2>Зміна пароля</h2>
                        <div className={css.resetPasswordForms}>
                            <div className="d-flex flex-column position-relative">
                                <label htmlFor="newPassword">Новий пароль</label>
                                <input
                                    type={isNewPasswordShow ? 'text' : 'password'}
                                    id="newPassword"
                                    placeholder="Введіть новий пароль"
                                    {...registerForm2(userPassword.newpassword, {
                                        onChange: async (event) => await startTrigger(event.target.value, userPassword.newpassword),
                                        required: 'Введіть пароль',
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/,
                                            message: 'Пароль не відповідає критеріям',
                                        }
                                    })}
                                />
                                <div className={css.passwordEye}
                                     onClick={() => setIsNewPasswordShow(!isNewPasswordShow)}>
                                    <EyePassword isOpen={isNewPasswordShow}/>
                                </div>
                                {errorsForm2.newpassword && (
                                    <p title="Пароль повинен містити один символ, одну малу та велику літеру, одне число та бути в межах 8 та 16 символів"
                                       className={css.errorMessage}>{errorsForm2.newpassword.message}</p>)}
                            </div>
                            <div className="d-flex flex-column position-relative mx-5">
                                <label htmlFor="repeatPassword">Підтвердження нового паролю</label>
                                <input
                                    type={isConfirmPasswordShow ? 'text' : 'password'}
                                    id="repeatPassword"
                                    placeholder="Підтвердіть пароль"
                                    {...registerForm2(userPassword.confirmpassword, {
                                        onChange: async (event) => await startTrigger(event.target.value, userPassword.confirmpassword),
                                        required: 'Введіть підтвердження паролю',
                                        validate: (value) => value === getValuesForm2('newpassword') || 'Паролі повинні співпадати'
                                    })}
                                />
                                <div className={css.passwordEye}
                                     onClick={() => setIsConfirmPasswordShow(!isConfirmPasswordShow)}>
                                    <EyePassword isOpen={isConfirmPasswordShow}/>
                                </div>
                                {errorsForm2[userPassword.confirmpassword] && (
                                    <p className={css.errorMessage}>{errorsForm2[userPassword.confirmpassword].message}</p>)}
                            </div>
                        </div>
                        <button type="submit" disabled={passwordBtnDisabled}>Змінити пароль</button>
                    </form>

                    <div className={css.dangerSection}>
                        <div className={css.blockDate}>
                            {!!user.blockedStatus ? `Заблокований до ${lockoutDateTime}` : ''}
                        </div>
                        <div className={css.dangerButtons}>
                            <button className={css.unblockUser} disabled={unblockBtnDisabled}
                                    onClick={() => handleUnblock(lockoutData)}>
                                <svg width="14" height="14" viewBox="0 0 34 26" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M33.0733 0.0625H30.2648C29.871 0.0625 29.4974 0.243303 29.2563 0.552678L12.6907 21.5379L4.74738 11.4732C4.62722 11.3207 4.47406 11.1973 4.29939 11.1124C4.12473 11.0275 3.9331 10.9833 3.7389 10.983H0.930416C0.66122 10.983 0.512559 11.2924 0.677291 11.5013L11.6822 25.4433C12.1965 26.0942 13.1849 26.0942 13.7032 25.4433L33.3264 0.576786C33.4911 0.371875 33.3425 0.0625 33.0733 0.0625Z"
                                        fill="black"/>
                                </svg>
                                Розблокувати користувача
                            </button>
                            <button className={css.blockUser} disabled={blockBtnDisabled}
                                    onClick={() => handleBlock(lockoutData)}>
                                <svg width="14" height="14" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18 0C8.05982 0 0 8.05982 0 18C0 27.9402 8.05982 36 18 36C27.9402 36 36 27.9402 36 18C36 8.05982 27.9402 0 18 0ZM18 32.9464C9.74732 32.9464 3.05357 26.2527 3.05357 18C3.05357 14.4241 4.31116 11.1375 6.40848 8.56607L27.4339 29.5915C24.8625 31.6888 21.5759 32.9464 18 32.9464ZM29.5915 27.4339L8.56607 6.40848C11.1375 4.31116 14.4241 3.05357 18 3.05357C26.2527 3.05357 32.9464 9.74732 32.9464 18C32.9464 21.5759 31.6888 24.8625 29.5915 27.4339Z"
                                        fill="black"/>
                                </svg>
                                Заблокувати користувача
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {SettingsModalAdmin};