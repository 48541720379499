import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import  {PersistGate} from 'redux-persist/integration/react'

import App from './App';
import {store, persistor} from "./store";
import './index.css'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={baseUrl}>
                <App/>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
