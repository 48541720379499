import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";

import css from './Task.module.css';
import downloadButton from "../../../ArchivePage/Elements/Task/Elements/downloadButton.svg";
import eye from './Elements/eye.svg';
import cssArchive from '../../../ArchivePage/Elements/Task/Task.module.css'
import {InputAnswer} from "../../../ArchivePage/Elements/InputAnswer/InputAnswer";
import {assignmentService} from "../../../../../services/assignment.service";
import {AssignmentFile} from "../../../../Elements";
import {openModal} from "../../../../../store/slices/modalSlice";

export const day = (num, arr) => {
    if (num === (0 || '')) {
        return ''
    } else if (num === 1) {
        return num + arr[1]
    } else if (num >= 2 && num <= 4) {
        return num + arr[2]
    } else if (num >= 5 && num <= 20) {
        return num + arr[3]
    } else if (num % 10 === 1) {
        return num + arr[1]
    } else if (num % 10 === 2 || num % 10 === 3 || num % 10 === 4) {
        return num + arr[2]
    } else {
        return num + arr[3]
    }
}

const Task = ({assignment, content, isDisplayedRightPanel = true}) => {
    const dispatch = useDispatch()
    const photoRef = useRef();
    const {
        register,
        handleSubmit,
    } = useForm({
        defaultValues: {
            assignmentId: assignment.id,
            answers: assignment.answers.map((answer) => {
                return {answerId: answer.id}
            })
        }
    });

    const {tasksFile} = useSelector(state => state.task);
    const [assignmentFile, setAssignmentFile] = useState(null)

    useEffect(() => {
        const file = tasksFile.find(x => x.assignmentId === assignment.id)
        if (file) {
            setAssignmentFile(file)
        }
    }, [tasksFile]);

    const openPDF = () => {
        const byteCharacters = atob(assignmentFile?.file.replace('data:application/pdf;base64,', ''));
        const byteArray = Uint8Array.from(byteCharacters, c => c.charCodeAt(0))
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    const downloadPdf = () => {
        const downloadLink = document.createElement('a')
        downloadLink.href = assignmentFile?.file;
        downloadLink.download = (assignmentFile?.fileName ?? 'file') + '.pdf'
        downloadLink.click()
    }

    const submit = (data) => {
        console.log(data);
        assignmentService.sendAnswer(data)
            .then(value => {
                if (value.status === 'Success') {
                    dispatch(openModal({message: value.message, class: 'success'}));
                }
            })
            .catch(res => {
                if (!!res?.response?.data?.message) {
                    dispatch(openModal({message: res.response.data.message, class: 'error'}));
                }
            })
    }

    const convert = (minutes) => {
        const calculatedDays = Math.floor(minutes / (24 * 60));
        const days = calculatedDays === 0 ? '' : calculatedDays;
        const remainingMinutes = minutes % (24 * 60);
        const calculatedHours = Math.floor(remainingMinutes / 60);
        const hours = calculatedHours === 0 ? '' : calculatedHours + ' год.';
        const calculateFinalMinutes = remainingMinutes % 60;
        const finalMinutes = calculateFinalMinutes === 0 ? '' : calculateFinalMinutes + ' хв.';
        return {days, hours, minutes: finalMinutes};
    };

    const arr = ['', ' день', ' дні', ' днів'];

    return (
        <>
            <div className={css.main}>
                <div className={css.left}>
                    <div className={css.title}>{content}</div>
                    <div ref={photoRef} className={css.forPhoto}>
                        {Boolean(assignment?.id) &&
                            <AssignmentFile width={photoRef?.current?.offsetWidth} assignmentId={assignment.id}/>}
                    </div>
                    <div className={css.line}></div>
                    <div
                        className={css.date}>{assignment.maxPeriod === assignment.period ?
                        `До архівації задачі ${day(convert(assignment.timeToArchive).days, arr)} ${convert(assignment.timeToArchive).hours} ${convert(assignment.timeToArchive).minutes}`
                        : `До наступної фази ${day(convert(assignment.minutesLeft).days, arr)} ${convert(assignment.minutesLeft).hours} ${convert(assignment.minutesLeft).minutes} До архівації задачі ${day(convert(assignment.timeToArchive).days, arr)}.`}</div>
                </div>
                <div className={`${css.upRight} ${!isDisplayedRightPanel ? css.upRightHide : ''}`}>
                    <div className={css.right}>
                        <div className={css.answer}>
                            <div className={css.answerText}>
                                <div className={css.standardText}>Відповідь</div>
                                <div className={`${cssArchive.rating} ${cssArchive.tooltip}`}>
                                    <div className={`${cssArchive.star} ${cssArchive.tooltip}`}></div>
                                    <div className={`${cssArchive.starRating}`}>{assignment.difficulty}</div>
                                    <span className={cssArchive.toolTipText}>
                                        <div className={cssArchive.popupText}>
                                        Складність задачі
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(submit)}>
                                <div className={css.inputs}>
                                    {assignment.answers.map((answer, index) => <InputAnswer key={answer.id}
                                                                                            register={register}
                                                                                            answer={answer}
                                                                                            index={index}
                                                                                            status={assignment?.answers[index]?.resultType}
                                                                                            value={assignment?.answers[index]?.value}/>)}
                                </div>
                                {assignment?.status === 'Done' ? <div style={{marginBottom:'24px'}}></div> :
                                <button className={css.button} type={"submit"}>
                                    <div className={cssArchive.arrow}>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.5534 6.79793L0.483083 0.244802C0.429958 0.218239 0.36902 0.211989 0.311208 0.226052C0.247045 0.241914 0.191781 0.282554 0.157517 0.339075C0.123253 0.395595 0.112782 0.46339 0.128395 0.527614L1.47527 6.03074C1.49558 6.11355 1.55652 6.18074 1.63777 6.2073L3.94558 6.99949L1.63933 7.79168C1.55808 7.8198 1.49715 7.88543 1.4784 7.96824L0.128395 13.4792C0.114333 13.537 0.120583 13.5979 0.147145 13.6495C0.208083 13.7729 0.358083 13.8229 0.483083 13.762L13.5534 7.24636C13.6018 7.22293 13.6409 7.1823 13.6659 7.13543C13.7268 7.01043 13.6768 6.86043 13.5534 6.79793ZM1.66902 11.9136L2.45496 8.70105L7.06746 7.11824C7.10339 7.10574 7.13308 7.07761 7.14558 7.04011C7.16746 6.97449 7.13308 6.90418 7.06746 6.88074L2.45496 5.29949L1.67215 2.09949L11.4846 7.0198L1.66902 11.9136Z"
                                                fill="black" fillOpacity="0.85"/>
                                        </svg>
                                    </div>
                                    <div className={cssArchive.forLabel}>Відправити відповідь</div>
                                </button>
                                }
                            </form>
                        </div>
                        <div className={css.task}>
                            <div className={css.standardText} style={{marginBottom: "24px"}}>Прикріплена задача</div>
                            <div className={css.file}>
                                <div className={css.fileStyle}>
                                    <div className={css.pdfSvg}></div>
                                    <div className={css.fileName}>
                                        <div className={css.pdfName}>Файл задачі</div>
                                        <div
                                            className={css.pdfSize}>{(assignmentFile?.file?.length / 1024 / 1024).toFixed(2)} МВ
                                        </div>
                                    </div>
                                </div>
                                <div className={css.svgs}>
                                    <div className={css.forDownload}>
                                        <button className={css.downloadButton} onClick={openPDF}
                                                disabled={!Boolean(assignmentFile?.file)}>
                                            <div><img src={eye} className={css.imgButton} alt="..."/></div>
                                        </button>
                                    </div>
                                    <div className={css.forDownload}>
                                        <button className={css.downloadButton} onClick={downloadPdf}
                                                disabled={!Boolean(assignmentFile?.file)}>
                                            <div><img src={downloadButton} className={css.imgButton} alt="..."/></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export {Task};