import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {homeService} from "../../services/home.service";

export const getAllContent = createAsyncThunk(
    'home/getAllContent',
    async (_, {rejectWithValue}) => {
        try {
            return await homeService.index();
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
)

export const homeSlice = createSlice({
    name: 'home',
    initialState: {
        content: null,
        status: null,
        error: null
    },
    reducers: {
        setContent: (state, action) => {
            state.content = action.payload.data;
        }
    },
    extraReducers: {
        [getAllContent.pending]: (state, action) => {
            state.status = 'pending';
            state.error = null;
        },
        [getAllContent.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.content = action.payload.data;
        },
        [getAllContent.rejected]: (state, action) => {
            state.status = 'rejected';
            state.error = action.payload;
        }
    }
})

export const {
    setContent
} = homeSlice.actions;
export default homeSlice.reducer;