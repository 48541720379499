import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {useSelector} from "react-redux";

import {DefaultLayout} from "./components/Layouts/DefaultLayout/DefaultLayout";
import {UserLayout} from "./components/Layouts/UserLayout/UserLayout";
import {AdminLayout} from "./components/Layouts/AdminLayout/AdminLayout";
import {
    ArchivePage, ForbiddenPage,
    HomePage, LogoutPage, NotFoundPage,
    PasswordSubmit, RatingsPage,
    EmailConfirm, Login, Register,
    RegisterAfterPage, TasksPage,
    LoginBasePage, Recovery,
    HomeUnregister
} from "./components/Pages";
import {AdminTasksPage, AdminUsersPage} from "./components/Pages";

function App() {
    const {user} = useSelector(state => state.auth);
    if (user?.id) {
        if (user.isAdmin === true) {
            return (
                <>
                    <Routes>
                        <Route path="/" element={<AdminLayout/>}>
                            <Route index element={<HomePage/>}/>
                            <Route path="admin/tasks" element={<AdminTasksPage/>}/>
                            <Route path="admin/users" element={<AdminUsersPage/>}/>
                            <Route path="logout" element={<LogoutPage/>}/>
                            <Route path="forbidden" element={<ForbiddenPage/>}/>
                            <Route path="*" element={<NotFoundPage/>}/>
                        </Route>
                    </Routes>
                </>
            );
        } else {
            return (
                <>
                    <Routes>
                        <Route path="/" element={<UserLayout/>}>
                            <Route index element={<HomeUnregister/>}/>
                            <Route path="homeUser" element={<HomePage/>}/>
                            <Route path="archive" element={<ArchivePage/>}/>
                            <Route path="ratings" element={<RatingsPage/>}/>
                            <Route path="tasks" element={<TasksPage/>}/>
                            <Route path="logout" element={<LogoutPage/>}/>
                            <Route path="forbidden" element={<ForbiddenPage/>}/>
                            <Route path="*" element={<NotFoundPage/>}/>
                        </Route>
                    </Routes>
                </>
            );
        }
    } else {
        return (
            <>
                <Routes>
                    <Route path="/" element={<DefaultLayout/>}>
                        <Route index element={<HomeUnregister/>}/>
                        <Route path="user/register" element={<LoginBasePage><Register/></LoginBasePage>}/>
                        <Route path="user/register/verify" element={<RegisterAfterPage />} />
                        <Route path="user/login" element={<LoginBasePage><Login/></LoginBasePage>}/>
                        <Route path="user/password/recovery" element={<LoginBasePage><Recovery/></LoginBasePage>}/>
                        <Route path="user/password/submit" element={<LoginBasePage><PasswordSubmit/></LoginBasePage>}/>
                        <Route path="user/email/confirm" element={<EmailConfirm/>}/>
                        <Route path="forbidden" element={<ForbiddenPage/>}/>
                        <Route path="*" element={<NotFoundPage/>}/>
                    </Route>
                </Routes>
            </>
        );
    }
}

export default App;