import React from 'react';

import css from '../Task/Task.module.css'

const InputAnswer = ({register, answer, index, status, value}) => {

    const setEmptyOrNumber = (num) => {
        if (num && num?.length) {
            return num
        }
        return null
    };

    return (
        <div className={css.answerLable}>
            <span className={`${css.forLabel} ${css.forLabelText}`}>{index + 1}.</span>
            <div className={css.inputWithSvg}>
                <div className={css.inputAbsolute}>

                    {
                        {
                            'Success':
                                <>
                                    <input
                                        className={`${css.answerInput} ${css.answerInputSuccess}`}
                                        type="number"
                                        placeholder={value}
                                        {...register(`answers.${index}.answer`, {
                                            setValueAs: setEmptyOrNumber
                                        })}
                                        disabled
                                    />
                                    <svg className={css.svg} width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.3392 5.16016H10.5017C10.3196 5.16016 10.1464 5.24766 10.0392 5.39766L7.23209 9.29051L5.96066 7.52623C5.85352 7.37801 5.68209 7.28873 5.49816 7.28873H4.66066C4.54459 7.28873 4.47673 7.42087 4.54459 7.51551L6.76959 10.6012C6.82215 10.6746 6.89145 10.7344 6.97172 10.7756C7.052 10.8168 7.14095 10.8384 7.2312 10.8384C7.32145 10.8384 7.4104 10.8168 7.49067 10.7756C7.57095 10.7344 7.64024 10.6746 7.6928 10.6012L11.4535 5.38694C11.5232 5.2923 11.4553 5.16016 11.3392 5.16016Z"
                                            fill="#73D13D"/>
                                        <path
                                            d="M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8 14.6429C4.33214 14.6429 1.35714 11.6679 1.35714 8C1.35714 4.33214 4.33214 1.35714 8 1.35714C11.6679 1.35714 14.6429 4.33214 14.6429 8C14.6429 11.6679 11.6679 14.6429 8 14.6429Z"
                                            fill="#73D13D"/>
                                    </svg>
                                </>,
                            'Fail':
                                <>
                                    <input
                                        className={`${css.answerInput} ${css.answerInputFail}`}
                                        type="number"
                                        step={0.000001}
                                        defaultValue={value}
                                        {...register(`answers.${index}.answer`, {
                                            setValueAs: setEmptyOrNumber
                                        })}
                                    />
                                    <svg className={css.svg} width="14" height="14" viewBox="0 0 14 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.70916 4.52905C9.70916 4.4603 9.65291 4.40405 9.58416 4.40405L8.55291 4.40874L6.99978 6.2603L5.44822 4.4103L4.41541 4.40562C4.34666 4.40562 4.29041 4.4603 4.29041 4.53062C4.29041 4.5603 4.30134 4.58843 4.32009 4.61187L6.35291 7.03374L4.32009 9.45405C4.30121 9.47695 4.29074 9.50562 4.29041 9.5353C4.29041 9.60405 4.34666 9.6603 4.41541 9.6603L5.44822 9.65561L6.99978 7.80405L8.55134 9.65405L9.58259 9.65874C9.65134 9.65874 9.70759 9.60405 9.70759 9.53374C9.70759 9.50405 9.69666 9.47593 9.67791 9.45249L7.64822 7.03218L9.68103 4.6103C9.69978 4.58843 9.70916 4.55874 9.70916 4.52905Z"
                                            fill="#FF4D4F"/>
                                        <path
                                            d="M7 0C3.13437 0 0 3.13437 0 7C0 10.8656 3.13437 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13437 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z"
                                            fill="#FF4D4F"/>
                                    </svg>
                                </>,
                            'Input':
                                <input
                                    className={css.answerInput}
                                    type="number"
                                    step={0.000001}
                                    defaultValue={value}
                                    {...register(`answers.${index}.answer`, {
                                        setValueAs: setEmptyOrNumber
                                    })}
                                />,
                            'None':
                                <input
                                    className={css.answerInput}
                                    type="number"
                                    step={0.000001}
                                    placeholder="Введіть відповідь"
                                    {...register(`answers.${index}.answer`, {
                                        setValueAs: setEmptyOrNumber
                                    })}
                                />,
                            default:
                                <input
                                    className={css.answerInput}
                                    type="number"
                                    step={0.000001}
                                    placeholder="Введіть відповідь"
                                    {...register(`answers.${index}.answer`, {
                                        setValueAs: setEmptyOrNumber
                                    })}
                                />,
                        }[status]
                    }
                </div>
                <span className={css.maxScore}>{answer.userScore ?? 0} / {answer.currentScore}</span>
            </div>
        </div>
    );
};

export {InputAnswer};