import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from '../SubjectAndPeriod.module.css';
import {
    getUsersForALlTime,
    selectUser,
    setSelectedPeriod,
    setSorting
} from "../../../../../store/slices/userSlice";

const Period = () => {

    const dispatch = useDispatch();
    const {selectedPeriod} = useSelector(state => state.user);
    const authUser = useSelector(state => state.auth);

    const handleRadioChange = (event) => {
        const newSelectedPeriod = event.target.value;
        dispatch(getUsersForALlTime()).then((data) => {
            if (Array.isArray(data.payload)) {
                const currentUser = data.payload.find(x => x.id === authUser.user.id)
                dispatch(selectUser({user: currentUser}))
                dispatch(setSelectedPeriod(newSelectedPeriod));
                dispatch(setSorting());
            }
        });

    };

    return (
        <div className={css.body}>
            <div className={css.main}>
                <div className={css.mainText}>Період</div>
                <div>
                    <label className={css.container}>
                        Поточний семестр
                        <input
                            checked={selectedPeriod === 'current'}
                            type={'radio'}
                            name="period"
                            value="current"
                            onChange={handleRadioChange}
                        />
                        <span className={css.checkmark}></span>
                    </label>
                    <label className={css.container}>
                        Весь час
                        <input
                            checked={selectedPeriod === 'all'}
                            type={'radio'}
                            name="period"
                            value="all"
                            onChange={handleRadioChange}
                        />
                        <span className={css.checkmark}></span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export {Period};