import Joi from "joi";

export const ResetPasswordValidator = Joi.object({
    token: Joi.any(),
    email: Joi.any(),
    password: Joi.string().regex(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)')).min(8).max(16)
        .required()
        .messages({
            'string.empty':  'Пароль не може бути порожнім',
            'string.min': `Пароль повинен містити більше {#limit} символів`,
            'string.max': `Пароль повинен містити не більше ніж {#limit} символів`,
            'string.pattern.base': 'Пароль повинен містити одну малу та велику літеру, одне число та бути в межах 8 та 16 символів'
        }),

    confirmPassword: Joi.string().required().valid(Joi.ref('password')).messages({
        'any.only': 'Паролі не співпадають',
    })
})

export const LoginValidator = Joi.object({
    UserNameOrEmail: Joi.string().min(2).max(50).required().messages({
        'string.empty':  'Поле не може бути порожнім',
        'string.min': `Поле повинне містити більше {#limit} символів`,
        'string.max': `Поле повинне містити не більше ніж {#limit} символів`,
    }),
    password: Joi.string().regex(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)')).min(8).max(16)
        .required()
        .messages({
            'string.empty':  'Пароль не може бути порожнім',
            'string.min': `Пароль повинен містити більше {#limit} символів`,
            'string.max': `Пароль повинен містити не більше ніж {#limit} символів`,
            'string.pattern.base': 'Пароль повинен містити одну малу та велику літеру, одне число та бути в межах 8 та 16 символів'
        })
})
