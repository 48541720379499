import React from 'react';

import {Top3User} from "../Top3User/Top3User";
import {Top3Master} from "../Top3Master/Top3Master";
import css from './Top3Users.module.css';

const Top3Users = ({users}) => {
    return (
        <div className={css.main}>
            <div className={css.position}></div>
            <div className={css.backBorder}>
                <div className={css.backBorderInside}></div>
            </div>
            <div className={`${css.leaders} ${css.text}`}>Лідери</div>
            <div className={css.users}>
                {users[1] && <Top3User marginTop={"40px"} marginFromText={"40"} user={users[1]} place={2}/>}
                {users[0] && <Top3Master user={users[0]}/>}
                {users[2] && <Top3User marginTop={"32px"} marginFromText={"32"} user={users[2]} place={3}/>}
            </div>
        </div>
    );
};

export {Top3Users};