import React, {useState} from 'react';
import { useLocation } from "react-router-dom";

import { userService } from "../../../services/user.service";
import { openModal } from "../../../store/slices/modalSlice";
import { store } from "../../../store";
import css from "./RegisterAfterPage.module.css";
import emailHexagon from "../../../assets/images/emailHexagon.svg";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { titles } from '../../../configs/titles';

const RegisterAfterPage = () => {
    useDocumentTitle(titles.emailConfirm);

    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    const emailToVerify = { email: location.state?.dataToSend };

    const submit = (e) => {
        setIsLoading(true)
        userService.resendVerify(e)
            .then(value => {
                if (value.status === 'Success') {
                    store.dispatch(openModal({message: 'Лист на підтвердження був надісланий знову'}));
                }
            })
            .catch(res => {
                if (!!res?.response?.data?.message) {
                    store.dispatch(openModal({message: res?.response?.data?.message}));
                }
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <div>

        <div className={css.afterPage}>
            <div></div>
            <div className={css.message}>
                <div>
                    <img className={css.messageLogo} src={emailHexagon} alt="Email Logo"/>
                </div>
                <p>
                    <h2><b>Підтвердіть<br/>електронну пошту</b></h2>
                    <p>Ми відправили вам лист на <b>{emailToVerify.email}</b>.<br/>Будь ласка, перевірте лист, щоб
                        підтвердити свій аккаунт!<br/> Якщо лист не потрапив у головну папку, перевірте, будь ласка, папку спам</p>
                </p>
            </div>
            <div className={css.noEmailFooter}>
                <p>
                    Не отримали листа? Перевірте спам або спробуйте
                    <button className={css.buttonResend}
                            onClick={() => submit(emailToVerify)}
                            disabled={isLoading}>
                        <svg fill="#FF0D9A" width="24px" height="40px" viewBox="0 0 32 32"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M 16 4 C 10.886719 4 6.617188 7.160156 4.875 11.625 L 6.71875 12.375 C 8.175781 8.640625 11.710938 6 16 6 C 19.242188 6 22.132813 7.589844 23.9375 10 L 20 10 L 20 12 L 27 12 L 27 5 L 25 5 L 25 8.09375 C 22.808594 5.582031 19.570313 4 16 4 Z M 25.28125 19.625 C 23.824219 23.359375 20.289063 26 16 26 C 12.722656 26 9.84375 24.386719 8.03125 22 L 12 22 L 12 20 L 5 20 L 5 27 L 7 27 L 7 23.90625 C 9.1875 26.386719 12.394531 28 16 28 C 21.113281 28 25.382813 24.839844 27.125 20.375 Z"/>
                        </svg>
                        Відправити знову
                    </button>
                </p>
            </div>
        </div>
        </div>
    );
};

export { RegisterAfterPage };