import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from './Top3Master.module.css';
import {selectUser} from "../../../../../store/slices/userSlice";
import {UserPhotos} from "../../../../Elements";
import {day} from "../../../TasksPage/Elements/Task/Task";

const Top3Master = ({user}) => {

    const {id, fullName, scoreMath, scorePhysics} = user;
    const dispatch = useDispatch();
    const arr = ['', ' бал', ' бали', ' балів'];

    const {selectedSubject} = useSelector(state => state.user);
    const userScore = selectedSubject === 'all' ? scoreMath + scorePhysics : (selectedSubject === 'math' ? scoreMath : scorePhysics)

    return (
        <div onClick={() => dispatch(selectUser({user}))} className={'d-flex flex-column align-items-center'}>
            <div className={css.main}>
                <div style={{position: "absolute", top: 0}}>
                    <div className={css.crown}></div>
                </div>
                <div className={css.forImg}>
                    <div className={css.colorBox}></div>
                    <div className={css.imgBox}>
                        <div className={css.wrap}>
                            <UserPhotos id={id} fullName={fullName} place={1} fontSize={76}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.text}>{fullName}</div>
            <div className={css.score}>{day(userScore, arr)}</div>
        </div>
    );
};

export {Top3Master};