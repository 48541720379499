import React from 'react';

import {LeftPanel, Users} from "./Elements";
import css from "./RatingsPage.module.css";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { titles } from '../../../configs/titles';

const RatingsPage = () => {
    useDocumentTitle(titles.ratings);

    return (
        <div className={css.main}>
            <div className={css.leftPanel}><LeftPanel/></div>
            <div className={css.rightPanel}><Users/></div>
        </div>
    );
};

export {RatingsPage};