import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";

import css from "./register.module.css";
import cssBase from "../loginBasePage.module.css";
import {EyePassword, LoginLogo, TwoPnkLogo} from "../../../Elements";
import {userService} from "../../../../services/user.service";
import {store} from "../../../../store";
import {openModal} from "../../../../store/slices/modalSlice";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import {titles} from "../../../../configs/titles";

const Register = () => {
    useDocumentTitle(titles.register);
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordShow, setIsPasswordShow] = useState(false)
    const [isConfPasswordShow, setConfIsPasswordShow] = useState(false)
    const navigate = useNavigate();

    const user =
        {
            nickname: 'nickname',
            email: 'email',
            password: 'password',
            confirmpassword: 'confirmPassword',
            photo: 'photo'
        };

    const {
        setError,
        trigger,
        handleSubmit,
        getValues,
        register,
        clearErrors,
        formState: {errors}
    } = useForm();

    const submit = async (createUser) => {
        setIsLoading(true)
        userService.create(createUser)
            .then(value => {
                console.log(value)
                if (value.status === 'Success') {
                    navigate('./verify', { state: { dataToSend: createUser.email } });
                }
            })
            .catch(response => {
                if (!!response?.response?.data?.errors) {
                    for (const key of response.response.data.errors) {
                        if (!user[key.field.toLowerCase()]) {
                            setError('error', {type: 'server', message: key.message})
                        } else {
                            setError(user[key.field.toLowerCase()], {type: 'server', message: key.message})
                        }
                    }
                }else{
                    store.dispatch(openModal({message: 'Щось пішло не так, ми працюємо над цією проблемою', class: 'error'}));
                }
            })
            .finally(() => setIsLoading(false))
    }

    const isEmailAvailable = async (email) => {
        const {isSuccess, messages} = await userService.checkEmail(email)
        return isSuccess || messages
    }

    const isNicknameAvailable = async (nickname) => {
        const {isSuccess, messages} = await userService.checkNickname(nickname)
        return isSuccess || messages
    }

    const startTrigger = async (obj, name) => {
        console.log('trigger ' + obj)
        await trigger(name);
    }

    return (
        <>
            <div className={cssBase.leftPanelTopTextBox}>
                <h1 className={cssBase.leftPanelTopText}>Створіть аккаунт в</h1>
                <h1 className={cssBase.leftPanelTopText}>в <LoginLogo/> <TwoPnkLogo/></h1>
            </div>
            <form onSubmit={handleSubmit(submit)} className={cssBase.formBox}>
                {errors.error && (
                    <p className={css.errorClass} onClick={() => clearErrors('error')}>{errors.error.message}</p>)}
                {/*Name*/}
                <label className={cssBase.labelText}>Логін користувача: </label>
                <div className={cssBase.formElement}>
                    <div className={css.formElementOk}>
                        <input
                            className={errors.nickname ? cssBase.inputClassError : cssBase.inputClass}
                            {...register(user.nickname, {
                                onChange: async (event) => await startTrigger(event.target.value, user.nickname),
                                required: 'Введіть логін користувача',
                                minLength: {
                                    value: 2,
                                    message: 'Логін користувача повинне містити від 2 до 50 символів'
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9-_]*$/,
                                    message: 'Дозволені символи латині, цифри, ніжній і верхній дефіз',
                                },
                                maxLength: {
                                    value: 50,
                                    message: 'Логін користувача повиннен містити від 2 до 50 символів'
                                },
                                validate: {
                                    isAvailable: async (value) => {
                                        return await isNicknameAvailable(value)
                                    },
                                }
                            })}
                            placeholder={'Введіть логін користувача'}
                            type={'text'}
                        />
                    </div>
                    <p className={css.errorClass}>{errors.nickname && errors.nickname.message}</p>
                </div>
                {/*Email*/}
                <label className={cssBase.labelText}>Електронна пошта: </label>
                <div className={cssBase.formElement}>
                    <div className={css.formElementOk}>
                        <input
                            className={errors.email ? cssBase.inputClassError : cssBase.inputClass}
                            {...register(user.email, {
                                onChange: async (event) => await startTrigger(event.target.value, user.email),
                                required: 'Введіть свою електронну пошту',
                                pattern: {
                                    value: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
                                    message: 'Введено невірну адресу електронної пошти',
                                },
                                validate: {
                                    isAvailable: async (value) => {
                                        return await isEmailAvailable(value)
                                    },
                                }
                            })}
                            placeholder={'Введіть електронну пошту'}
                            type={'text'}
                        />
                    </div>
                    <p className={css.errorClass}>{errors.email && errors.email.message}</p>
                </div>
                {/*Password*/}
                <label className={cssBase.labelText}>Пароль</label>
                <div className={cssBase.passwordContainer}>
                    <input
                        className={errors.password ? cssBase.inputClassError : cssBase.inputClass}
                        {...register(user.password, {
                            required: 'Введіть пароль',
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/,
                                message: 'Пароль повинен містити одну малу та велику літеру, одне число та бути в межах 8 та 16 символів',
                            }
                        })}
                        placeholder={'Пароль'}
                        type={isPasswordShow ? 'text' : 'password'}
                    />
                    <div className={cssBase.inputClassEie} onClick={() => setIsPasswordShow(!isPasswordShow)}>
                        <EyePassword isOpen={isPasswordShow}/>
                    </div>
                </div>
                {/*ConfirmPassword*/}
                <div style={{marginTop: '16px'}} className={cssBase.passwordContainer}>
                    <input
                        className={errors[user.confirmpassword] ? cssBase.inputClassError : cssBase.inputClass}
                        {...register(user.confirmpassword, {
                            required: 'Введіть підтвердження паролю',
                            validate: (value) => value === getValues('password') || 'Паролі повинні співпадати'
                        })}
                        placeholder={'Підтвердження паролю'}
                        type={isConfPasswordShow ? 'text' : 'password'}
                    />
                    <div className={cssBase.inputClassEie} onClick={() => setConfIsPasswordShow(!isConfPasswordShow)}>
                        <EyePassword isOpen={isConfPasswordShow}/>
                    </div>
                </div>
                <p style={{height:'72px'}} className={css.errorClass}>{errors.password && errors.password.message}<br/>{errors[user.confirmpassword] && errors[user.confirmpassword].message}
                </p>
                <button className={cssBase.buttonEnter} type="submit"
                        disabled={isLoading}>{isLoading ? 'Loading...' : 'Зареєструватись'}</button>
            </form>
            <button className={cssBase.buttonRegister} onClick={() => navigate('/user/login')}>Вже маєте аккаунт?
                Ввійти
            </button>
        </>
    );
};

export {Register};