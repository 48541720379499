import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import css from './UserPhotos.module.css'
import {getUserPhoto, saveUsersDefaultPhoto} from "../../../store/slices/userSlice";
import {updateUserPhoto} from "../../../store/slices/authSlice";

const UserPhotos = ({id, fullName, place, fontSize}) => {

    const colors = ['#FFD8BF', '#BAE7FF', '#FFCCC7', '#FFE7BA', '#FFF1B8', '#FFFFB8', '#F4FFB8', '#D9F7BE', '#B5F5EC', '#D6E4FF', '#EFDBFF', '#EFDBFF' ,'#FFE400', '#E0893C', '#D6D6D6'];
    const textColors = ['#AD2102', '#0050B3', '#A8071A', '#AD4E00', '#AD6800', '#AD8B00', '#5B8C00', '#237804', '#006D75', '#10239E', '#391085', '#9E1068', '#443F00', '#593400', '#393939'];

    const [photo, setPhoto] = useState(null);
    const {usersPhoto} = useSelector(state => state.user);
    const {user} = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
            const userPhoto = usersPhoto.find(x => x.id === id)
            if (!userPhoto) {
                const num=  place > 0 ? place + 11 : Math.floor(Math.random() * 12)
                const defPhoto = {id, data: {error: true}, fullName, num}
                dispatch(saveUsersDefaultPhoto(defPhoto))
                setPhoto(defPhoto)

                dispatch(getUserPhoto({id}))
                    .then(result => {
                        const newPhoto = {id, data: result.payload, fullName, num}
                        setPhoto(newPhoto)
                        dispatch(saveUsersDefaultPhoto(newPhoto))
                        if (user.id === id) {
                            dispatch(updateUserPhoto(newPhoto?.data?.data))
                        }

                    })
            } else {
                setPhoto(userPhoto)
                if (user.id === id) {
                    dispatch(updateUserPhoto(userPhoto?.data?.data))
                }
            }
        }, [id, usersPhoto]
    );

    const userPhotoStyle = (num) => {
        return {
            backgroundColor: colors[num ?? 0],
            color: textColors[num ?? 0],
            fontSize: `${fontSize ?? 14}px`
        }
    };

    const getFirstLetter = (name) => {
        if (typeof name === 'string' && name.trim() !== '') {
            const words = name.split(' ');
            const capitalizedWords = words.map(word => word.charAt(0).toUpperCase());
            return  capitalizedWords.slice(0, 2).join('');
        }
        return 'U';
    }

    return (
        <>
            {
                photo &&
                photo.data?.error ?
                    <div className={css.userPhoto}>
                        <div className={`${css.text}`}
                             style={userPhotoStyle(photo.num)}>{getFirstLetter(photo.fullName)}</div>
                    </div>
                    :
                    <>
                        {photo?.data?.data &&
                            <img className={css.wrap} src={photo?.data?.data} alt={`Фото ${fullName}`}/>}
                    </>
            }

        </>
    );
};

export {UserPhotos};