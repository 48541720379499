import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import "../../../services/axios.service";
import css from "./HomeUnregister.module.css";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {titles} from "../../../configs/titles";
import bannerHome from "../../../assets/images/bannerHome.png"
import {
    description,
    archive,
    registration,
    rating,
    solution,
    common,
    news,
    nowRegistration,
    team,
    upShift,
    netLS,
    upshiftBlack,
    smallTeam
} from './index'
import {Slot} from "./Elements/slot";
import {useSelector} from "react-redux";


const HomeUnregister = () => {
    useDocumentTitle(titles.homePage);
    const texts = ['Мотивуй', 'Інформуй', 'Залучай'];
    const [prevIndex, setPrevIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate();
    const {user} = useSelector(state => state.auth);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
            setTimeout(() => {
                setPrevIndex((prevIndex) => (prevIndex + 1) % texts.length)
            }, 2000)
        }, 8000);

        return () => clearInterval(intervalId);
    }, [texts.length]);


    return (
        <div className={css.baseBody}>
            <div className={css.grey}>
                <div className={css.banerBase}>
                    <div className={`${css.textCycler} ${currentIndex === 0 || prevIndex === 0 ? css.visible : ''}`}>
                        {texts[0]}
                    </div>
                    <div className={`${css.textCycler} ${currentIndex === 1 || prevIndex === 1 ? css.visible : ''}`}>
                        {texts[1]}
                    </div>
                    <div className={`${css.textCycler} ${currentIndex === 2 || prevIndex === 2 ? css.visible : ''}`}>
                        {texts[2]}
                    </div>
                </div>
                <div className={css.contein}>
                    <div className={css.mytext}>
                        2πnK – це академічний турнір, де учні 8-11 класів можуть випробувати свої знання, працюючи над
                        задачами з математики та фізики.
                    </div>
                    <div className={css.conect}>
                        {user?.id ?
                            <button className={css.buttonEnter} onClick={() => navigate('/tasks')}>Розв’язати задачу
                            </button>
                            :
                            <button className={css.buttonEnter}
                                    onClick={() => navigate('/user/register')}>Зареєструватись
                            </button>
                        }
                        <button className={css.buttonConect} onClick={() => alert('Зв’яжіться з нами support@2pnk.in.net')}>Звʼязатись із нами
                        </button>
                    </div>
                </div>
                <div className={css.imgMain}>
                    <img src={bannerHome} alt={'banner'} className={css.responsiveImage}/>
                </div>
            </div>
            <div className={css.description}>
                <div className={css.what}>Що таке 2πnK?</div>
                2πnK – це академічний турнір, де учні 8-11 класів можуть випробувати свої знання, працюючи над задачами
                з математики та фізики. Гортай нижче, щоб дізнатися більше про правила участі й долучайся вже сьогодні!
            </div>
            <div className={css.imgMain}>
                <img src={team} alt={'team'} className={css.teamImage}/>
            </div>
            <div className={css.labelWork}>
                Як це працює?
            </div>
            <div className={css.setSlot}>
                <Slot image={registration} bigText={'Реєструйся'}
                      smallText={'Зареєструй акаунт, щоб випробувати 2πnK на повну!'}/>
                <Slot image={description} bigText={'Додавай опис'}
                      smallText={'Додай кілька слів про себе в описі профілю, аби інші користувачі дізналися про тебе.'}/>
                <Slot image={solution} bigText={'Розв’язуй задачі'}
                      smallText={'Розв’язуй задачі та отримуй бали! Чим швидше ти розв’яжеш задачу, тим більше отримаєш!'}/>
                <Slot image={rating} bigText={'Підвищуй рейтинг'}
                      smallText={'Знаходь себе та інших в рейтингу й починай спілкування!'}/>
                <Slot image={archive} bigText={'Переглядай архів'}
                      smallText={<>Закінчились задачі? Не проблема - прямуй до архіву й розв’язуй попередні
                          завдання<br/>(P.S. за них також можна отримати бали)</>}/>
                <div className={css.slot2}>
                    <img src={nowRegistration} alt={'item'}/>
                    {user?.id ?
                        <>
                            <div className={`${css.bigText} ${css.whiteText}`}>Вирішуй задачі вже сьогодні</div>
                            <div className={`${css.smallText} ${css.whiteText}`}>Вирішуй задачі та піднімай свій рівень
                                із математики та фізики.
                            </div>
                            <button className={css.butRegist} onClick={() => navigate('/tasks')}>Розвʼязати задачу
                            </button>
                        </>
                        :
                        <>
                            <div className={`${css.bigText} ${css.whiteText}`}>Зареєструйся вже сьогодні</div>
                            <div className={`${css.smallText} ${css.whiteText}`}>Вирішуй задачі та піднімай свій рівень
                                із математики та фізики.
                            </div>
                            <button className={css.butRegist} onClick={() => navigate('/user/register')}>Зареєструватись
                            </button>
                        </>}
                </div>
            </div>
            <div className={css.target}>
                <div className={css.team}><img className={css.imgTeam} src={smallTeam} alt={'команда'}/></div>
                <div className={css.goals}>
                    <div className={css.ourGoals}>Наші цілі</div>
                    <div className={css.aboutUs}>Ми, команда Інкві, створили 2πnK, щоб вирішити проблеми, що постали
                        перед сучасною освітою.<br/><br/>
                        Стикаючись з малою взаємодією академічної молоді між собою, ми прагнули заснувати змагання, що
                        створять спільноту учнів, об’єднаних однією ціллю.<br/><br/>
                        Наш проект відкриває шлях до підвищення інтересу до навчання та покращення комунікації між
                        олімпіадниками.
                    </div>
                </div>
            </div>
            <div className={css.labelWork}>
                Давай поспілкуємось
            </div>
            <div className={css.setSlot}>
                <Slot image={news} bigText={'Новини'}
                      smallText={'Ми регулярно публікуємо новини про нас та розказуємо, як ми досягаємо результатів.'}
                      onClick={() => window.open('https://t.me/inkvi_2pink')}/>
                <Slot image={description} bigText={'Спілкування'}
                      smallText={'Якщо у вас є запитання, напишіть нам, і ми відповімо якнайшвидше.'}
                      onClick={() => alert('Зв’яжіться з нами support@2pnk.in.net')}/>
                <Slot image={common} bigText={'Спільнота'}
                      smallText={'Вступайте в нашу спільноту мотивованих школярів.'}
                      onClick={() => window.open('https://discord.gg/VwVj8V4mH9')}/>
            </div>
            <div className={css.sponsor}>
                <div className={css.labelWork}>Спонсори</div>
                <div className={css.spons}>
                    <div className={css.spon1}>
                        <img src={upshiftBlack} style={{width: "175px"}} alt={'upshift'}/>
                        <div className={css.sponsorText}>Це глобальна програма Дитячого фонду ООН (ЮНІСЕФ), що
                            реалізується у понад 40 країнах. Вона надає молоді й підліткам навички 21 століття
                            та ресурси, щоб визначити проблеми своїх громад та запропонувати інноваційні рішення цих
                            проблем.
                        </div>
                    </div>
                    <div className={css.spon1}>
                        <div style={{cursor: "pointer"}} onClick={() => {
                            window.location.href = 'https://netls.com.ua/en-us/'
                        }}>
                            <div>
                                <img src={netLS} style={{width: "175px"}} alt={'NetLs'}/>
                            </div>
                            <div className={css.sponsorText}>Сайт розроблений волонтерами програми стажування "Boot
                                Camp"
                                при компанії NetLS.
                                <br/><br/>
                                Network Leading Services - компанія, що спеціалізується на ІТ R&D, а також Dedicated
                                Development та розвиває ІТ-ринок з 2016 року. NetLS - це ефективна команда фахівців,
                                чия
                                експертиза охоплює повний цикл надання ІТ-послуг.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={css.hr}></div>
            <div className={css.upshift}>
                <div className={css.spText}>Цей сайт був створений в межах програми UPЅHIFT за підтримки ЮНІСЕФ та уряду
                    Норвегії. Його зміст є відповідальністю учасників програми UPSHIFT та не обов'язково відображає
                    позицію
                    ЮНІСЕФ та уряду Норвегії.
                </div>
                <div>
                    <img src={upShift} style={{width: "175px"}} alt={'upShift'}/>
                </div>
            </div>

        </div>
    );
};
export {
    HomeUnregister
};